import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allergy-faq',
  templateUrl: './allergy-faq.component.html',
  styleUrls: ['./allergy-faq.component.css']
})
export class AllergyFaqComponent implements OnInit {

  constructor() {
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
}

ngOnInit() {
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 
}
