import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {CookieService} from 'src/app/cookie.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SercallService {

 apiurl=localStorage.getItem("apiurl");
  
  
  headersoptions={
    headers:new HttpHeaders({"Content-Type":"application/json"
      
    }).append("api-key","123").append("Authorization","")
  }
  constructor(private cookieser:CookieService,private http:HttpClient,private route:Router) {  
    
  }
  postdata<T>(url,data)
  {
    let authtoken:string=localStorage.getItem("AuthToken")
    if(authtoken!=null)
    {  
      this.headersoptions.headers= new HttpHeaders({"Content-Type":"application/json","api-key":"123",
      "Authorization":"Bearer "+authtoken
    });  
       
      
    }
    
    return this.http.post<T>(this.apiurl+url,data,this.headersoptions).toPromise() 

  }
  getdata<T>(url)
  { 
    var htheaders = new HttpHeaders({"Content-Type":"application/json","api-key":"123"});
    
    
    let authtoken:string=localStorage.getItem("AuthToken")
    if(authtoken!=null)
    {
      htheaders = new HttpHeaders({"Content-Type":"application/json","api-key":"123",
      "Authorization":"Bearer "+authtoken
    });  
    
    }
     
    
    return this.http.get<T>(this.apiurl+url,{headers:htheaders}).toPromise() 
    // return this.http.get<T>(url,{params:bparams}).toPromise() 
   

  }
  CheckRefreshToken()
  { 
    var htheaders = new HttpHeaders({"Content-Type":"application/x-www-form-urlencoded"});
    
     
    
     
    let body = new URLSearchParams();
    body.set('grant_type', "refresh_token");
    body.set('refresh_token',localStorage.getItem("refresh_token")); 
    

    
     this.http.post<LoginModel.AccessToken>(this.apiurl+'token',body.toString(),{headers:htheaders}).toPromise().then(data=>{
      localStorage.setItem("AuthToken",data.access_token)
      localStorage.setItem("refresh_token",data.access_token)
      return true;
    }).catch(e=>{
    localStorage.removeItem("AuthToken")
    localStorage.removeItem("refresh_token")
    this.route.navigate(['/login'])
    })
   
    return false;
    // return this.http.get<T>(url,{params:bparams}).toPromise() 
   

  }
  
  
  PostDataWtihForm<T>(url,body)
  {  
     return this.http.post<T>(this.apiurl+url,body,{
      headers: new HttpHeaders({"Content-Type":"application/x-www-form-urlencoded"
        
    }) 
}).toPromise() 
    

  }
}
