import { Component, OnInit } from '@angular/core';
import { MetaService } from '../meta.service';
import { SercallService } from '../sercall.service';
import { Archievement, ArchievementResp, AboutContent, AboutContentResp, Testimonial, TestimonialResp, AboutFirstSectionResp, AboutSecondSectionResp, AboutThirdSectionResp } from '../StatusModel';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  weburl:string="";
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 30,
    width:350,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
   
  }
  constructor(private se:SercallService,private meta:MetaService) {
    this.meta.updateTitle();
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
}

ngOnInit() {
  this.weburl=localStorage.getItem("weburl");
    this.LoadAboutFirstContent();
    this.LoadAboutSecondContent();
    this.LoadAboutThirdContent();
    this.LoadArchievement();
    this.LoadTestimonials();
}
aboutfirstsectioncontent:AboutContent[];
LoadAboutFirstContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<AboutFirstSectionResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.aboutfirstsectioncontent=data.ResponseObject.AboutFirstSections;
        localStorage.setItem("AboutFirstSections",JSON.stringify(this.aboutfirstsectioncontent));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadAboutFirstContent();

    }
  }
});
}
aboutsecondsectioncontent:AboutContent[];
LoadAboutSecondContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<AboutSecondSectionResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.aboutsecondsectioncontent=data.ResponseObject.AboutSecondSections;
        localStorage.setItem("AboutSecondSections",JSON.stringify(this.aboutsecondsectioncontent));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadAboutSecondContent();

    }
  }
});
}
aboutthirdsectioncontent:AboutContent[];
LoadAboutThirdContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<AboutThirdSectionResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.aboutthirdsectioncontent=data.ResponseObject.AboutThirdSections;
        localStorage.setItem("AboutThirdSections",JSON.stringify(this.aboutthirdsectioncontent));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadAboutThirdContent();

    }
  }
});
}
archievement:Archievement[];
LoadArchievement()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<ArchievementResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.archievement=data.ResponseObject.Archievements;
        localStorage.setItem("Archievement",JSON.stringify(this.archievement));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadArchievement();

    }
  }
});
}

testimonials:Testimonial[];
LoadTestimonials()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<TestimonialResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.testimonials=data.ResponseObject.Testimonials;
        localStorage.setItem("Testimonials",JSON.stringify(this.testimonials));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadTestimonials();

    }
  }
});
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 

}
