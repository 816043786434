import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { Routes,RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  
import { CarouselModule } from 'ngx-owl-carousel-o';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';


import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { HttpClientModule } from '@angular/common/http';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ItemComponent } from './item/item.component';
import { CatheaderComponent } from './catheader/catheader.component';
import { CartComponent } from './cart/cart.component'; 
import { FilterPipe} from './filter.pipe';
import { HomeComponent } from './home/home.component';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { LoaderComponent } from './loader/loader.component';
import { MenuComponent } from './menu/menu.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { DeliveryPolicyComponent } from './delivery-policy/delivery-policy.component';
import { LazyChildComponent} from './lazy-child/lazy-child.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { LoginComponent } from './login/login.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { OrderstatusComponent } from './orderstatus/orderstatus.component';
import { CommunicationServiceService } from './communication-service.service';
import { DatePipe } from '@angular/common';
import { AllergyFaqComponent } from './allergy-faq/allergy-faq.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OfferZoneComponent } from './offer-zone/offer-zone.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

const appRoutes:Routes=[
  {path:'',component:HomeComponent,data: {
    title: 'All in One take away polmont | Best take away in polmont',
    keywords: 'All in One take away Polmont, Best take away in Polmont, All In One take away,All In One Polmont,All in One, Testy Food in Polmont,Quality Food in polmont,take away in polmont',
    description:'All In One take away is the best take away in polmont, we provide food delivery in Hallglen, Brightons, Redding Grangemouth, Whitecross and near by areas.'
  } },
  {path:'about',component:AboutusComponent,data: {
    title: 'All in One polmont | Take away in polmont',
    keywords: 'All in One , Take away in Polmont, Best take away in Polmont, All In One take away,All In One Polmont,All in One, Take Away,Quality Food in polmont',
    description:'All In One take away polmont provide food delivery in Linlithgow, Maddiston, Reddingmuirhead, california, shieldhill, rumford, Falkirk and near by areas.'
  } },
  {path:'menu',component:MenuComponent,data: {
    title: 'All In One Polmont | Menu',
    keywords: 'All In One Polmont, Biryanis, Rollovers, Kebabs, Hoggy, Garlic bread, burger, pizza, Mexican Dishes, Pasta, Pasta, Tandoori Steamers, Indian Curries ,Quality Food in polmont',
    description:'All In One Polmont is a takeaway that provide best Quality Biryanis, Rollovers, Kebabs, Hoggy, Garlic bread, burger, pizza, Mexican Dishes, Pasta, Pasta, Tandoori Steamers, Indian Curries in polmont area.'
  } },
  {path:'gallery',component:GalleryComponent,data: {
    title: 'All In One Take away | Gallery',
    keywords: 'All in One, Take away in Polmont, Best take away in Polmont, All In One take away,All In One Polmont,All in One, Take Away,Quality Food in polmont',
    description:'All In One take away polmont provide food delivery in Linlithgow, Maddiston, Reddingmuirhead, california, shieldhill, rumford, Falkirk and near by areas.'
  }}, 
  {path:'OfferZone',component:OfferZoneComponent,data: {
    title: 'All In One Take away | Offer Zone',
    keywords: 'All in One, Take away in Polmont, Best take away in Polmont, All In One take away,All In One Polmont,All in One, Take Away,Quality Food in polmont',
    description:'All In One take away polmont provide food delivery in Linlithgow, Maddiston, Reddingmuirhead, california, shieldhill, rumford, Falkirk and near by areas.'
  }}, 
  {path:'ContactUs',component:ContactUsComponent,data: {
    title: 'All In One Take away | Contact Us',
    keywords: 'All in One, Take away in Polmont, Best take away in Polmont, All In One take away,All In One Polmont,All in One, Take Away,Quality Food in polmont',
    description:'All In One take away polmont provide food delivery in Linlithgow, Maddiston, Reddingmuirhead, california, shieldhill, rumford, Falkirk and near by areas.'
  }}, 
  {path:'checkout',component:CheckoutComponent},
  {path:'login',component:LoginComponent},
  {path:'ContactUs',component:ContactUsComponent}, 
  {path:'Privacy_Policy',component:PrivacyPolicyComponent},
  {path:'Terms_Conditions',component:TermsAndConditionComponent},
  {path:'Delivery_Policy',component:DeliveryPolicyComponent},
  {path:'item/:catname/:catid',component:ItemComponent},
  {path:'MyAccount',component:MyaccountComponent},
  {path:'OrderStatus',component:OrderstatusComponent},
  {path:'Allergy',component:AllergyFaqComponent},
  {path:'ForgotPassword',component:ForgotPasswordComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    AboutusComponent,
    ContactUsComponent,
    ItemComponent,
    CatheaderComponent,
    CartComponent, 
    FilterPipe,
    HomeComponent,
    LoaderComponent,
    MenuComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    DeliveryPolicyComponent ,
    LazyChildComponent,
    CheckoutComponent,
    LoginComponent,
    MyaccountComponent,
    OrderstatusComponent,
    AllergyFaqComponent,
    ForgotPasswordComponent,
    GalleryComponent,
    OfferZoneComponent

  ],
  imports: [
    BrowserModule, 
    JwBootstrapSwitchNg2Module,
    CarouselModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, 
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0,100], // cool option, or ideal option when you have a fixed header on top.
      
     })//, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [DynamicScriptLoaderService,CommunicationServiceService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
