import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SercallService} from '../sercall.service';
import {tblstudent } from '../studentmodel'
// import { ThrowStmt } from '@angular/compiler';
 

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  
@ViewChild('input1') inputEl:ElementRef;
  l=new Array<tblstudent>();
 ind=0
  student=new tblstudent();
  firstname=''
  lastname=''
  rollno=''
  age=0
  ID=0
  constructor(private se:SercallService) { }

  ngOnInit() {
    this.inputEl.nativeElement.focus()
      this.se.getdata<tblstudent[]>('http://localhost:81/api/Values/Get').then(data=>{
console.log(data)
this.l=data

      })
 
  }
  clear()
  {
    this.firstname=''
    this.lastname=''
     
  this.rollno=''
  this.age=0
  this.ID=0
  }
 Add()
 {  


   this.ind++
   if(this.firstname.trim()=='')
   {
     this.firstname=''
    this.inputEl.nativeElement.focus()
    
    
    return
   }
 
   this.student=new tblstudent();
this.student.FIRSTNAME=this.firstname.trim()
this.student.LASTNAME=this.lastname.trim()
this.student.ROLLNO=Number.parseInt(this.rollno)
this.student.AGE= this.age
this.student.ID=this.ID
   this.se.postdata<tblstudent>('http://localhost:81/api/Values/Post',this.student).then(data=>{
     this.l.push(data)
   })
   this.clear()
   console.log(this.l) 
       this.inputEl.nativeElement.focus()
 }
 Remove(obj)
 {  
   if(confirm('Are  you sure?')){
  this.se.getdata<number>('http://localhost:81/api/Values/delete?id='+obj.ID).then(data=>{
    console.log(data)
     if(data>0)
    for (let index = 0; index < this.l.length; index++) {
      if(this.l[index].ID==obj.ID)
      {
        this.l.splice(index,1);
      }
    }
          })
        }
 }
 Edit(obj)
 {
  this.firstname=obj.FIRSTNAME
  this.lastname=obj.LASTNAME
   
this.rollno=obj.ROLLNO
this.age=obj.AGE
this.ID=obj.ID

for (let index = 0; index < this.l.length; index++) {
  if(this.l[index].ID==obj.ID)
  {
    this.l.splice(index,1);
  }
  
}

this.inputEl.nativeElement.focus()
 }
}
