import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-orderstatus',
  templateUrl: './orderstatus.component.html',
  styleUrls: ['./orderstatus.component.css']
})

export class OrderstatusComponent implements OnInit {
  LogoURL: SafeResourceUrl;
  Phone:string="";
  weburl:string="";
  constructor(@Inject(AppComponent) private AppCompoParent: AppComponent,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.weburl=localStorage.getItem("weburl");
    localStorage.removeItem('_cart');
    this.AppCompoParent.loadCart();
    this.LoadMap();
  }
  LoadMap()
{
  let url="";
    var arr = JSON.parse(localStorage.getItem('LogoAndContent'));
    arr.forEach(function(obj) {
     url=obj.About_h1;
  });
    this.LogoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.weburl+'/admin'+url);
    let aa="";
    var arr = JSON.parse(localStorage.getItem('ContactDetail'));
  arr.forEach(function(obj) {
    aa=(obj.HeaderPhone);
  });
  this.Phone=aa;
}

}
