import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SercallService } from '../sercall.service';
import { AppComponent } from '../app.component'
import { ActivatedRoute, Router } from '@angular/router';
import { NewItem, ShopStatus, ShopTiming, ShopTimingResp } from '../StatusModel';
import { CommunicationServiceService } from '../communication-service.service';
import { DatePipe } from '@angular/common';
import { element } from 'protractor';
import * as $ from "jquery";
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private datePipe: DatePipe, private se: SercallService, @Inject(AppComponent) private AppCompoParent: AppComponent, private route: Router,private activeRoute: ActivatedRoute) {

    this.removeSripts();
    this.loadScript('assets/js/scripts.js');

  }
  customOptions: any = {
    margin:0,
    loop: true,
    autoplay:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    // autoplay:false,
    navSpeed: 500,
    navText: [ '<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>' ],
    nav: true,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4  
      }
    },
   
  }
  l = new Array();
  cl = new Array();
  Bases = new Array<MenuModel.Item>();
  Toppings = new Array<MenuModel.Item>();
  MealDealItems = new Array<MenuModel.DealCourse>();
  UniqueId: number = 0;
  SelecteDeliv: number = 0;
  showModal: boolean = false;
  showMDModal: boolean = false;
  showLoader: boolean = false;
  selectedBase= new Array<MenuModel.Item>();
  selectedToppings = new Array<MenuModel.Item>();
  selectedItem: MenuModel.Item;
  selectedMealdealItem: MenuModel.Item;
  errorMsg: string = "";
  backDrop: boolean = false;
  MealdDealHeading: string = "";
  currentMealDeal: Array<MenuModel.DealCourse>;
  dealCourseCurrentIndex: number = 0;
  MealdSubheading: string = "";
  showCompleteMealDeal: boolean = false;
  totalMealdealQty: number = 0;
  currentDeal: MenuModel.DealCourse;
  orderInstructions: string = "";
  newitem: NewItem;
  shopStatus: any;
  currentTime: string = "";
  currentTime1: string = "";
  shopEnabled: boolean = true;
  hourlist = new Array();
  minutelist = new Array();
  selectedHour: number;
  selectedMin: string = "00";
  type:string ="";
  isDisabledState: boolean = true;
  show:boolean = false;   
  labelText:string="";
  isDisabledAddButton:boolean=true;
  weburl:string="";
  Version:string="";
   
  ngOnInit() { 
    this.Version=localStorage.getItem("Version");
    this.weburl=localStorage.getItem("weburl");
    if (localStorage.getItem("DelType") != null)
    {
      this.SelecteDeliv = Number.parseInt(localStorage.getItem("DelType"));
    // var tog = $(this).data('happy');
    $('happy').prop('value', this.SelecteDeliv);
    
    $('a[data-toggle="happy"]').not('[data-title="'+this.SelecteDeliv+'"]').removeClass('active').addClass('notActive radioBtnDeActive');
    $('a[data-toggle="happy"][data-title="'+this.SelecteDeliv+'"]').removeClass('notActive radioBtnDeActive').addClass('active');
  }
    else {
      this.DeliverySel();
    }
    //this.showLoader=true;
    this.LoadMenu();
    this.LoadShopTimming();
  
  }
  ShopOpenClose()
  {
    this.shopStatus = JSON.parse(localStorage.getItem("ShopStatus"));
    var dat = new Date();
    if (this.shopStatus != undefined) {

      if (this.shopStatus.Shop_Status) {
      //
        if (this.shopStatus.PreOrder) {
          this.currentTime = this.datePipe.transform(dat, "HH:mm");
          var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


          var shoptimming = JSON.parse(localStorage.getItem("ShopTimingsforpreorder"));
          var shoptime = shoptimming.filter(function (t, u) {
            return t.Day_Name == days[dat.getDay()];
          })
          if (shoptime != undefined && shoptime.length > 0) {
            var regex = new RegExp(':', 'g');
            var from = shoptime[0].Opening_Time;
            var to = shoptime[0].Closing_Time;
            this.currentTime1=from;
            var CTime=this.datePipe.transform(dat, "HH:mm:ss");
            console.log(CTime);
            console.log(parseInt(CTime.replace(regex, ''), 10) );
            console.log(parseInt(from.replace(regex, ''), 10));
            if(parseInt(CTime.replace(regex, ''), 10) < parseInt(from.replace(regex, ''), 10))
            {
                this.show = true;  
                this.labelText="Shop is closed now but you can preorder if you want."; 
                this.type="Schedule";
                this.isDisabledState=true;
                var fromhour = Number.parseInt(from.replace(':', '').substring(0, 2))
                var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
                this.selectedHour = fromhour;
                for (let index = fromhour; index <= tohour; index++) {
                  this.hourlist.push(index)
                }
                for(let i = 0; i<60; i++)
                  {
                    this.minutelist.push(i);
                  }
                this.hourlist.pop();
              this.minutelist.pop();
              this.currentTime1 = from;

            }
            else
            {
              const d = new Date();
              let minute = d.getMinutes();
              let hour = d.getHours();
              this.currentTime1 = hour.toString() + ":" + minute.toString();
              this.type="ASAP";
              this.isDisabledState=false;
              var fromhour = Number.parseInt(CTime.replace(':', '').substring(0, 2))
              var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
              this.selectedHour = fromhour;
              // if(this.selectedMin=="05")
              // {
              //   this.selectedMin=this.datePipe.transform(dat, "mm");
              // }
              for (let index = fromhour; index <= tohour; index++) {
                this.hourlist.push(index)
              }
              for(let i = dat.getMinutes(); i<60; i++)
              {
                this.minutelist.push(i);
              }
              this.selectedMin = dat.getMinutes().toString();
              //minutelist
              this.hourlist.pop();
              this.minutelist.pop();
            }
            console.log(from.replace(':', '').substring(0, 4), to.replace(':', '').substring(0, 4));

          }
        }
        else {
          this.shopEnabled = false;
        }

      }
      else 
      {
        this.show = true;  
        this.labelText="Shop is now closed you cannot order."; 
        this.isDisabledAddButton=false;
        // Menu Button Disable, Message - Shop Is Closed 
      
      }
    }
  }
  LoadMenu() {
    const firstTime = localStorage.getItem('ReloadPage');
    if(!firstTime){
      localStorage.setItem('ReloadPage','loaded');
      window.location.reload();
    }else {
      localStorage.removeItem('ReloadPage');
    }

    var payload = {
      "Key": "123",
      "Id": "0",
      "Size_Id": "0",
      "OrderType": "",
      "CmdType": "Get_Full_Data"
    }
    this.se.postdata<MenuModel.MainReponse>('v1/api/get-full-menu', payload).then(data => {

      if (data.ResponseCode == 0) {
        data.ResponseObject.cat.forEach(element => {
          element.Url_Cat_Name = element.Cat_Name.split(' ').join('_').toLowerCase();
          element.Item.forEach(element1 => {
            element1.LabelAdded = 0;
            element1.ItemUniqueid = Math.random().toString(16).slice(2);
            if (element1.SizeExists) {
              element1.selectedSize = element1.size[0];
            }
          });
          element.height = "0"
        });
        this.l = data.ResponseObject.cat;
        this.loadCart();
        if (this.cl.length > 0)
          this.UniqueId = Math.max.apply(Math, this.cl.map(function (o) { return o.UniqueId; }));
        else
          this.UniqueId = 0;

        this.showLoader = false;

      }

    })
  }
  shoptimming:ShopTiming[];
  ShopTimingsforpreorder:ShopTiming[];
  shopStatus2:ShopStatus;
  LoadShopTimming()
  {
    var date = new Date();
     
    var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":this.datePipe.transform(date,"yyyy-MM-dd HH:mm"),"IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Shop_Info"};
    this.se.postdata<LoginModel.CommonRreponse<ShopTimingResp>>('v1/api/get-set-information-more',payload).then(data=>{
        if(data.ResponseCode==0)
        {
          if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
          {
            this.ShopTimingsforpreorder=data.ResponseObject.ShopTimingsforpreorders;
            this.shoptimming=data.ResponseObject.ShopTimings;
            this.shopStatus2=data.ResponseObject.ShopStatus[0];
            localStorage.setItem("ShopStatus",JSON.stringify(this.shopStatus2));
            localStorage.setItem("ShopTimming",JSON.stringify(this.shoptimming));
            localStorage.setItem("ShopTimingsforpreorder",JSON.stringify(this.ShopTimingsforpreorder));
            this.ShopOpenClose();
          }
        }
    }).catch(e => {
  
      if (e.status == 401) {
        if (this.se.CheckRefreshToken()) {
          this.LoadShopTimming();
  
        }
      }
    });
  
  }
  GetHourMin(){
    var hour= this.selectedHour;
    var min= this.selectedMin;
    this.currentTime1=hour +":" +min;
   }
  public removeSripts() {
    var arr = document.getElementsByTagName('script');
    for (let index = 0; index < arr.length; index++) {
      if (arr[index].src.includes('scripts.js')) {
        arr[index].remove();
        break;
      }
    }
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;

    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  getCart() {
    var cart = localStorage.getItem('_cart')
    if (cart != null && cart != "")
      return JSON.parse(cart)
    else
      return [];


  }
  DeliverySel() {
    localStorage.setItem("DelType", this.SelecteDeliv.toString());
    this.loadCart();
  }
  Addtocart(obj: MenuModel.Item, addbase: boolean) {

    if (obj == null)
      return;
    if (!this.shopEnabled)
      return;
    var cart = new Array();

    cart = this.getCart();
    var added = false
    var citem = Object.assign({}, obj);
    citem.Qty = 1;

    if (!obj.SizeExists) {
      citem.ItemTotal = citem.Qty * obj.Dilivery_Price;
      citem.CartName = citem.Name;
    }
    else {
      citem.ItemTotal = citem.Qty * obj.selectedSize.Dilivery_Price;
      // if(obj.Cat_Id)
      this.l.forEach(element => {
        if(element.id = obj.Cat_Id)
        {
          if(element.Show_Variety_Size==0)
          {
            citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
          }
          else{
             citem.CartName = citem.selectedSize.Name + ' - ' + citem.Name
          }
        }
        
      });
      // citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
    }

    citem.UniqueId = this.UniqueId + 1;
    this.UniqueId = this.UniqueId + 1;

    if (citem.Is_Item != 4) {
      if (addbase) {
        this.selectedItem = citem;
        this.selectedItem.ItemUniqueid = Math.random().toString(16).slice(2);
      }
      cart.push(citem);


      if (addbase) {
        // if ((!citem.BaseExists) && (!citem.Auto_Show_Extras) && (!citem.ToppingsExists)) {
          if ((!citem.BaseExists) && (!citem.Auto_Show_Extras)) {
          if (citem.LabelCount > 0 && (citem.LabelCount > citem.LabelAdded)) {
            var payload = {
              "Key": "123",
              "Id": citem.Item_Id,
              "Size_Id": citem.Size_Id,
              "OrderType": "",
              "CmdType": "Get_Label_With_Details_On_ItemId"
            };
            this.showLoader = true;

            this.selectedBase = [];
            this.se.postdata<MenuModel.DealResponse>('v1/api/get-Label-Course', payload).then(data => {
              if (data.ResponseCode == 0) {

                this.totalMealdealQty = 0;
                let dealuniqueid: number = 0;
                this.dealCourseCurrentIndex = 0;
                this.currentMealDeal = data.ResponseObject.DealCourse;
                this.currentMealDeal.map((ele) => {
                  ele.DealUniquId = ++dealuniqueid;
                  ele.Item.map((el) => {

                    el.ItemUniqueid = Math.random().toString(16).slice(2);

                  })
                })


                let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
                  return mealEle.Is_Fix == false;
                });
                if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
                  filteredMealdealItems[0].visible = true;
                  this.MealdSubheading = filteredMealdealItems[0].Course_Name;
                  filteredMealdealItems.map((filitems) => {
                    this.totalMealdealQty += filitems.Selection_Quantity;
                    filitems.Selected_Quantity = 0;
                    filitems.Item.map((ele) => {
                      ele.Qty = 0;
                      if (ele.SizeExists)
                        ele.selectedSize = ele.size[0];
                    });
                  })

                  this.MealDealItems = filteredMealdealItems;
                  this.MealdDealHeading = citem.Name;

                  this.showLoader = false;
                  this.backDrop = true;
                  this.showMDModal = true;
                }
                else {
                  
                  this.MealDealCompleted();
                }


              }
            });
          }
          else {
            localStorage.setItem('_cart', JSON.stringify(cart));
            this.loadCart()
          }
        }
        else {
          if(citem.SizeExists)
          {
             payload = {
              "Key": "123",
              "Id": citem.Item_Id,
              "Size_Id": citem.selectedSize.Size_Id,
              "OrderType": "",
              "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
            };
          }
          else{
            payload = {
             "Key": "123",
             "Id": citem.Item_Id,
             "Size_Id": citem.Size_Id,
             "OrderType": "",
             "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
           };
          }
          this.showLoader = true;

          this.selectedBase = [];
          this.se.postdata<BaseModel.MainReponse>('v1/api/get-base-toppings', payload).then(data => {


            this.Bases = data.ResponseObject.Base;
            this.Toppings = data.ResponseObject.Topping;



            this.Bases.forEach(element => {
              element.ItemUniqueid = Math.random().toString(16).slice(2);
              if (this.SelecteDeliv == 0) {
                element.ShowPrice_Price = element.Dilivery_Price;
                element.Multiselection_Base = citem.Multiselection_Base;
              }
              else
                element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
                element.Multiselection_Base = citem.Multiselection_Base;
            });
            this.Toppings.forEach(element => {
              element.ItemUniqueid = Math.random().toString(16).slice(2);
              if (this.SelecteDeliv == 0) {
                element.ShowPrice_Price = element.Dilivery_Price;
                element.Multiselection_Base = citem.Multiselection_Base;
              }
              else
                element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
                element.Multiselection_Extras = citem.Multiselection_Extras;
            });
            this.showLoader = false;
            this.selectedToppings = new Array<MenuModel.Item>();
            this.ShowModal();
          })

        }
      }
      else {
        if (citem.LabelCount > 0 && (citem.LabelCount > citem.LabelAdded)) {
          var LabeId="";
        if(this.selectedToppings.length>=0)
        {
          
          LabeId=this.selectedItem.Item_Id.toString();
          for (let index = 0; index < this.selectedToppings.length; index++) {
            if(this.selectedToppings[index].LabelCount>0)
            {
              LabeId=LabeId+","+this.selectedToppings[index].Item_Id.toString();
              
            }
          }
        }
          // var payload = {
          //   "Key": "123",
          //   "Id": citem.Item_Id,
          //   "Size_Id": citem.Size_Id,
          //   "OrderType": "",
          //   "CmdType": "Get_Label_With_Details_On_ItemId"
          // };
          var payload = {
            "Key": "123",
            "Id": citem.Item_Id,
            "Size_Id": citem.Size_Id,
            "OrderType": LabeId,
            "CmdType": "Get_Label_With_Details_On_ItemId_Multiple"
          };
          this.showLoader = true;

          this.selectedBase = [];
          this.se.postdata<MenuModel.DealResponse>('v1/api/get-Label-Course', payload).then(data => {
            if (data.ResponseCode == 0) {

              this.totalMealdealQty = 0;
              let dealuniqueid: number = 0;
              this.dealCourseCurrentIndex = 0;
              this.currentMealDeal = data.ResponseObject.DealCourse;
              this.currentMealDeal.map((ele) => {
                ele.DealUniquId = ++dealuniqueid;
                ele.Item.map((el) => {

                  el.ItemUniqueid = Math.random().toString(16).slice(2);

                })
              })

              let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
                return mealEle.Is_Fix == false;
              });
              if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
                filteredMealdealItems[0].visible = true;
                this.MealdSubheading = filteredMealdealItems[0].Course_Name;
                filteredMealdealItems.map((filitems) => {
                  this.totalMealdealQty += filitems.Selection_Quantity;
                  filitems.Selected_Quantity = 0;
                  filitems.Item.map((ele) => {
                    ele.Qty = 0;
                    if (ele.SizeExists)
                      ele.selectedSize = ele.size[0];
                  });
                })

                this.MealDealItems = filteredMealdealItems;
                this.MealdDealHeading = citem.Name;

                this.showLoader = false;
                this.backDrop = true;
                this.showMDModal = true;
              }
              else {
                this.MealDealCompleted();
              }


            }
          });
        }
        else {
          localStorage.setItem('_cart', JSON.stringify(cart));
          this.loadCart()
        }
      }
    }
    else {
      if (addbase) {
        this.selectedItem = citem;
        this.selectedItem.ItemUniqueid = Math.random().toString(16).slice(2);
      }
      cart.push(citem);
      if (addbase) {
        //4 item type
        var Dealpayload = {
          "Key": "123",
          "Id": citem.Item_Id,
          "Size_Id": "0",
          "OrderType": "",
          "CmdType": "Get_Course_With_Details_On_DealId"
        }
        this.showLoader = true;
        this.se.postdata<MenuModel.DealResponse>('v1/api/get-Deal-Course', Dealpayload).then(data => {
          if (data.ResponseCode == 0) {

            this.totalMealdealQty = 0;
            let dealuniqueid: number = 0;
            this.dealCourseCurrentIndex = 0;
            this.currentMealDeal = data.ResponseObject.DealCourse;
            this.currentMealDeal.map((ele) => {
              ele.DealUniquId = ++dealuniqueid;
              ele.Item.map((el) => {

                el.ItemUniqueid = Math.random().toString(16).slice(2);

              })
            })

            let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
              return mealEle.Is_Fix == false;
            });
            if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
              filteredMealdealItems[0].visible = true;
              this.MealdSubheading = filteredMealdealItems[0].Course_Name;
              filteredMealdealItems.map((filitems) => {
                this.totalMealdealQty += filitems.Selection_Quantity;
                filitems.Selected_Quantity = 0;
                filitems.Item.map((ele) => {
                  ele.Qty = 0;
                  if (ele.SizeExists)
                    ele.selectedSize = ele.size[0];
                });
              })

              this.MealDealItems = filteredMealdealItems;
              this.MealdDealHeading = citem.Name;

              this.showLoader = false;
              this.backDrop = true;
              this.showMDModal = true;
            }


          }
        });
      }
      else {
        localStorage.setItem('_cart', JSON.stringify(cart));
        this.loadCart()

      }

    }

    return false;
  }
  subtotal = 0;
  cartempty = true

  decCartItems(obj: MenuModel.Item) {

    this.decCart(obj);

  }
  checkZeroPrice(obj: MenuModel.Item) {
    if (obj.DineIn_Price == 0 && obj.Dilivery_Price == 0 && obj.Takeaway_Collection_Bar_Price == 0) {
      return false;
    }
    if (obj.SizeExists && obj.selectedSize.DineIn_Price == 0 && obj.selectedSize.Dilivery_Price == 0 && obj.selectedSize.Takeaway_Collection_Bar_Price == 0) {
      return false;
    }
    return true;
  }
  incCartItems(obj: MenuModel.Item) {

    if (this.checkZeroPrice(obj))
      this.incCart(obj);

  }
  decCart(obj: MenuModel.Item) {

    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].ItemUniqueid == obj.ItemUniqueid) {
        cart[i].Qty = cart[i].Qty - 1
        if (!cart[i].SizeExists) {
          cart[i].ItemTotal = cart[i].Qty * obj.Dilivery_Price;

        }
        else {
          cart[i].ItemTotal = cart[i].Qty * obj.selectedSize.Dilivery_Price;
        }


        if (cart[i].Qty <= 0) {
          cart.splice(i, 1);
        }

        added = true;
        break;
      }

    }

    localStorage.setItem('_cart', JSON.stringify(cart))
    this.loadCart();
    if (obj.ItemUniqueid != undefined) {
      let childitems = cart.filter((ele: MenuModel.Item) => ele.Parent_Id == obj.ItemUniqueid);
      childitems.forEach(element => {
        this.decCart(element);
      });
    }
    return false
  }
  incCart(obj: MenuModel.Item) {

    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].ItemUniqueid == obj.ItemUniqueid) {
        cart[i].Qty = cart[i].Qty + 1
        if (!cart[i].SizeExists) {
          cart[i].ItemTotal = cart[i].Qty * obj.Dilivery_Price;


        }
        else {
          cart[i].ItemTotal = cart[i].Qty * obj.selectedSize.Dilivery_Price;
        }

        added = true;
        break;
      }

    }

    localStorage.setItem('_cart', JSON.stringify(cart))
    this.loadCart()
    if (obj.ItemUniqueid != undefined) {
      let childitems = cart.filter((ele: MenuModel.Item) => ele.Parent_Id == obj.ItemUniqueid);
      childitems.forEach(element => {
        this.incCart(element);
      });
    }
    return false
  }

  loadCart() {
    this.showLoader = true;
    this.subtotal = 0
    this.cl = this.getCart();
    if (this.cl.length > 0) {
      this.cartempty = false
      for (var i = 0; i < this.cl.length; i++) {
        if (this.SelecteDeliv == 0) {
          if (this.cl[i].selectedSize != null)
            this.cl[i].ItemTotal = (this.cl[i].selectedSize.Dilivery_Price * this.cl[i].Qty);
          else
            this.cl[i].ItemTotal = (this.cl[i].Dilivery_Price * this.cl[i].Qty);

        }
        else {
          if (this.cl[i].selectedSize != null)
            this.cl[i].ItemTotal = (this.cl[i].selectedSize.Takeaway_Collection_Bar_Price * this.cl[i].Qty);
          else
            this.cl[i].ItemTotal = (this.cl[i].Takeaway_Collection_Bar_Price * this.cl[i].Qty);
        }
        if (this.cl[i].Parent_Id != undefined) {
          this.cl[i].CartName = '-' + this.cl[i].Name;
          if (this.cl[i].selectedSize != null)
            this.cl[i].CartName = '-' + this.cl[i].Name + '-' + this.cl[i].selectedSize.Name;
        }
        this.subtotal += this.cl[i].ItemTotal;
      }
    }
    else {
      this.cartempty = true
      this.UniqueId = 0;
    }
    this.AppCompoParent.loadCart();
    this.showLoader = false;

  }
  sizeChange(item: MenuModel.Item) {
  }
  public ShowModal() {
    this.showModal = true;
    this.backDrop = true;
  }
  HideModal() {
    this.showModal = false;
    if (this.showModal == false && this.showMDModal == false)
      this.backDrop = false;
  }
  HideMealModal() {
    this.showMDModal = false;
    if (this.showModal == false && this.showMDModal == false)
      this.backDrop = false;
  }
  SelectBase(obj: MenuModel.Item,e,type) {
    if(type=='single')
    {
      this.selectedBase=[];
    obj.Parent_Id = this.selectedItem.ItemUniqueid
    obj.SizeExists = false;
    obj.BaseExists = false;
    obj.ToppingsExists = false;
    obj.LabelAdded=0;
    this.selectedBase.push(obj);
    }
    else
    {
    if (e.target.checked) {
      obj.Parent_Id = this.selectedItem.ItemUniqueid
      obj.SizeExists = false;
      obj.BaseExists = false;
      obj.ToppingsExists = false;
      obj.LabelAdded=0;
      this.selectedBase.push(obj);
    }
    else {
      this.selectedBase.forEach((ele, i) => {
        if (ele.Price_Id == obj.Price_Id) {
          delete this.selectedBase[i];
        }

      });
    }
  }
  }
  SelectToppings(obj: MenuModel.Item, e,type) {
    if(type=='single')
    {
      this.selectedToppings=[];
    obj.Parent_Id = this.selectedItem.ItemUniqueid
    obj.SizeExists = false;
    obj.BaseExists = false;
    obj.ToppingsExists = false;
    obj.LabelAdded=0;
    this.selectedToppings.push(obj);
    }
    else
    {
    if (e.target.checked) {
      obj.Parent_Id = this.selectedItem.ItemUniqueid
      obj.SizeExists = false;
      obj.BaseExists = false;
      obj.ToppingsExists = false;
      obj.LabelAdded=0;
      this.selectedToppings.push(obj);
    }
    else {
      this.selectedToppings.forEach((ele, i) => {
        if (ele.Price_Id == obj.Price_Id) {
          delete this.selectedToppings[i];
        }

      });
    }
  }
  }
  AddtocartExplicit(obj:MenuModel.Item)
  {
    var cart = new Array();

    cart = this.getCart();
    var added = false
    var citem = Object.assign({}, obj);
    citem.Qty = 1;

    if (!obj.SizeExists) {
      citem.ItemTotal = citem.Qty * obj.Dilivery_Price;
      citem.CartName = citem.Name;
    }
    else {
      citem.ItemTotal = citem.Qty * obj.selectedSize.Dilivery_Price;
      this.l.forEach(element => {
        if(element.id = obj.Cat_Id)
        {
          if(element.Show_Variety_Size==0)
          {
            citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
          }
          else{
             citem.CartName = citem.selectedSize.Name + ' - ' + citem.Name
          }
        }
        
      });
      //  citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
    }

    citem.UniqueId = this.UniqueId + 1;
    this.UniqueId = this.UniqueId + 1;

    
     
      cart.push(citem);
      localStorage.setItem('_cart', JSON.stringify(cart));
      this.loadCart();

  }
  AddBaseToppings() {
    if (this.selectedMealdealItem == null) {

      if (this.selectedItem.LabelCount == 0) {
        this.Addtocart(this.selectedItem, false);
        if (this.selectedBase != null) {
          // this.selectedBase.Name='  -'+this.selectedBase.Name
          this.selectedBase.forEach(base=>{
            this.Addtocart(base, false);
          });
        }
        if (this.selectedItem.Auto_Add_Free_Toppings) {
          var freetoppings = this.selectedItem.Free_Toppings_Ids.split(',');
          freetoppings.forEach(element => {
            this.Toppings.filter(function (ele) {
              return ele.Item_Id == parseInt(element);
            }).forEach(element1 => {
              let cele = Object.assign({}, element1);
              cele.Parent_Id = this.selectedItem.ItemUniqueid
              cele.SizeExists = false;
              cele.BaseExists = false;
              cele.ToppingsExists = false;
              cele.DineIn_Price = 0;
              cele.Dilivery_Price = 0;
              cele.Takeaway_Collection_Bar_Price = 0;
              cele.ThirdParty_Price = 0;
              cele.MD_Price = 0;
              // cele.Name='  -'+cele.Name           
              this.Addtocart(cele, false);
            });
          });

        }

        let freeqty = 0;
        this.selectedToppings.forEach(ele => {
          let cele = Object.assign({}, ele);
          if (this.selectedItem.Free_Toppings_Qty > 0) {
            let freetoppingsids = this.selectedItem.Free_Toppings_Ids.split(',');
            let freetoppingexist = false;
            let checkarray = freetoppingsids.filter((fele) => {
              return cele.Item_Id == parseInt(fele);
            });
            if (checkarray.length > 0)
              freetoppingexist = true;

            if (freeqty < this.selectedItem.Free_Toppings_Qty && freetoppingexist) {
              cele.DineIn_Price = 0;
              cele.Dilivery_Price = 0;
              cele.Takeaway_Collection_Bar_Price = 0;
              cele.ThirdParty_Price = 0;
              cele.MD_Price = 0;
              freeqty++;
            }

          }
          //cele.Name='  -'+cele.Name
          this.Addtocart(cele, false);
        });


      }
      else {
        this.Addtocart(this.selectedItem, false);
      }
      this.HideModal();
    }
    else {

      this.selectedMealdealItem.selectedBase = [];
      this.selectedMealdealItem.selectedToppings = new Array<MenuModel.Item>();
      if (this.selectedBase != null) {
        //this.selectedBase.Name='  -'+this.selectedBase.Name
        this.selectedMealdealItem.selectedBase = this.selectedBase;
      }
      if (this.selectedMealdealItem.Auto_Add_Free_Toppings) {
        var freetoppings = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
        freetoppings.forEach(element => {
          this.Toppings.filter(function (ele) {
            return ele.Item_Id == parseInt(element);
          }).forEach(element1 => {
            let cele = Object.assign({}, element1);
            cele.Parent_Id = this.selectedMealdealItem.ItemUniqueid
            cele.SizeExists = false;
            cele.BaseExists = false;
            cele.ToppingsExists = false;
            cele.DineIn_Price = 0;
            cele.Dilivery_Price = 0;
            cele.Takeaway_Collection_Bar_Price = 0;
            cele.ThirdParty_Price = 0;
            cele.MD_Price = 0;
            //cele.Name='  -'+cele.Name           
            this.selectedMealdealItem.selectedToppings.push(cele);
          });
        });

      }

      let freeqty = 0;
      this.selectedToppings.forEach(ele => {
        let cele = Object.assign({}, ele);
        
        let freetoppingsids = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
        let checkarray1 = freetoppingsids.filter((fele) => {
          if (freeqty < this.currentDeal.Extras_Quantity && cele.Item_Id == parseInt(fele)) {
            cele.DineIn_Price = 0;
            cele.Dilivery_Price = 0;
            cele.Takeaway_Collection_Bar_Price = 0;
            cele.ThirdParty_Price = 0;
            cele.MD_Price = 0;
            freeqty++;
          }
        });
        if (this.selectedMealdealItem.Free_Toppings_Qty > 0) {
          let freetoppingsids = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
          let freetoppingexist = false;
          let checkarray = freetoppingsids.filter((fele) => {
            return cele.Item_Id == parseInt(fele);
          });
          if (checkarray.length > 0)
            freetoppingexist = true;

          if (freeqty < this.selectedMealdealItem.Free_Toppings_Qty && freetoppingexist) {
            cele.DineIn_Price = 0;
            cele.Dilivery_Price = 0;
            cele.Takeaway_Collection_Bar_Price = 0;
            cele.ThirdParty_Price = 0;
            cele.MD_Price = 0;
            freeqty++;
          }

        }
        //cele.Name='  -'+cele.Name
        this.selectedMealdealItem.selectedToppings.push(cele);

      });
      if (this.currentDeal.selectedItems == undefined)
        this.currentDeal.selectedItems = new Array<MenuModel.Item>();
      this.currentDeal.selectedItems.push(Object.assign({}, this.selectedMealdealItem));
      this.selectedMealdealItem.Qty++;
      this.currentDeal.Selected_Quantity++;
      this.ShowCompleteButton();
      this.HideModal();

    }
  }
  ClearErrorMsg() {
    setTimeout(() => {
      this.errorMsg = "";
    }, 2000);
  }
  AddtocartMeal(citem: MenuModel.Item, deal: MenuModel.DealCourse, addbase: boolean, addRemo: string) {
    this.currentDeal = deal;
    if (this.selectedItem && this.selectedItem.LabelCount > 0) {
      this.selectedItem.LabelAdded++;
    }
    if (deal.Selected_Quantity >= deal.Selection_Quantity && addRemo == "add") {
      this.ShowDealCourseWithIndex('next');
      return false;
    }
    if (deal.Selected_Quantity == 0 && addRemo == "remove" && citem.Qty == 0)
      return false;

    if (addRemo == "add") {
      if (!citem.BaseExists) {
        citem.Qty++;
        deal.Selected_Quantity++;
        if (this.currentDeal.selectedItems == undefined)
          this.currentDeal.selectedItems = new Array<MenuModel.Item>();
        this.currentDeal.selectedItems.push(Object.assign({}, citem));

      }
      else {
        citem.ItemUniqueid = Math.random().toString(16).slice(2);
        this.selectedMealdealItem = citem;
        var payload = {
          "Key": "123",
          "Id": citem.Item_Id,
          "Size_Id": citem.Size_Id,
          "OrderType": "",
          "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
        };
        this.showLoader = true;

        this.selectedBase = [];
        this.se.postdata<BaseModel.MainReponse>('v1/api/get-base-toppings', payload).then(data => {


          this.Bases = data.ResponseObject.Base;
          this.Toppings = data.ResponseObject.Topping;



          this.Bases.forEach(element => {
            element.ItemUniqueid = Math.random().toString(16).slice(2);
            if (this.SelecteDeliv == 0) {
              element.ShowPrice_Price = element.Dilivery_Price;
              element.Multiselection_Base = citem.Multiselection_Base;
            }
            else
              element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
              element.Multiselection_Base = citem.Multiselection_Base;
          });
          this.Toppings.forEach(element => {
            element.ItemUniqueid = Math.random().toString(16).slice(2);
            if (this.SelecteDeliv == 0) {
              element.ShowPrice_Price = element.Dilivery_Price;
              element.Multiselection_Extras=citem.Multiselection_Extras;
            }
            else
              element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
              element.Multiselection_Extras=citem.Multiselection_Extras;
          });
          this.showLoader = false;
          this.selectedToppings = new Array<MenuModel.Item>();
          this.ShowModal();
        })

      }

    }
    if (deal.Selected_Quantity >= deal.Selection_Quantity && addRemo == "add") {
      this.ShowDealCourseWithIndex('next');

    }
    this.ShowCompleteButton();


  }
  ShowCompleteButton() {
    let selectedTotalItems: number = 0;
    this.MealDealItems.map((ele) => {
      selectedTotalItems += ele.Selected_Quantity;
    })
    this.showCompleteMealDeal = (selectedTotalItems == this.totalMealdealQty);
  }
  ShowDealCourseWithIndex(type: string) {



    if (this.dealCourseCurrentIndex == 0 && type == "prev")
      return false;
    if (this.dealCourseCurrentIndex == this.MealDealItems.length - 1 && type == "next") {
      return false;

    }
    if (type == "next") {
      this.dealCourseCurrentIndex++;
    }
    else
      this.dealCourseCurrentIndex--;



    for (let index = 0; index < this.MealDealItems.length; index++) {
      const element = this.MealDealItems[index];
      element.visible = false;
    }
    this.MealDealItems[this.dealCourseCurrentIndex].visible = true;
    this.MealdSubheading = this.MealDealItems[this.dealCourseCurrentIndex].Course_Name;
    this.currentDeal = this.MealDealItems[this.dealCourseCurrentIndex];

  }
  MealDealCompleted() {

    if (this.selectedItem == null)
      return;
      this.AddtocartExplicit(this.selectedItem);
    if (this.selectedItem && this.selectedItem.BaseExists && this.selectedBase != null) {
      // this.selectedBase.Name='  -'+this.selectedBase.Name
      this.selectedBase.forEach(base=>{
        this.Addtocart(base, false);
      })
    }
    if (this.selectedItem.Auto_Add_Free_Toppings) {
      var freetoppings = this.selectedItem.Free_Toppings_Ids.split(',');
      freetoppings.forEach(element => {
        this.Toppings.filter(function (ele) {
          return ele.Item_Id == parseInt(element);
        }).forEach(element1 => {
          let cele = Object.assign({}, element1);
          cele.Parent_Id = this.selectedItem.ItemUniqueid
          cele.SizeExists = false;
          cele.BaseExists = false;
          cele.ToppingsExists = false;
          cele.DineIn_Price = 0;
          cele.Dilivery_Price = 0;
          cele.Takeaway_Collection_Bar_Price = 0;
          cele.ThirdParty_Price = 0;
          cele.MD_Price = 0;
          // cele.Name='  -'+cele.Name           
          this.Addtocart(cele, false);
        });
      });

    }

    let freeqty = 0;
    if (this.selectedItem && this.selectedItem.ToppingsExists && this.selectedToppings != null) {
    this.selectedToppings.forEach(ele => {
      let cele = Object.assign({}, ele);
      if (this.selectedItem.Free_Toppings_Qty > 0) {
        let freetoppingsids = this.selectedItem.Free_Toppings_Ids.split(',');
        let freetoppingexist = false;
        let checkarray = freetoppingsids.filter((fele) => {
          return cele.Item_Id == parseInt(fele);
        });
        if (checkarray.length > 0)
          freetoppingexist = true;

        if (freeqty < this.selectedItem.Free_Toppings_Qty && freetoppingexist) {
          cele.DineIn_Price = 0;
          cele.Dilivery_Price = 0;
          cele.Takeaway_Collection_Bar_Price = 0;
          cele.ThirdParty_Price = 0;
          cele.MD_Price = 0;
          freeqty++;
        }

      }
      //cele.Name='  -'+cele.Name
      this.Addtocart(cele, false);
    });
  }
    if (this.MealDealItems && this.MealDealItems.length > 0)
    {
      
      
      this.currentMealDeal.map((ele) => {
        if(!ele.Is_Fix)
        {
          let filteredNonFix=this.MealDealItems.filter((w)=>{
            return w.DealUniquId==ele.DealUniquId;
          });
          filteredNonFix.map((selNonFix)=>{

            selNonFix.selectedItems.map((ele1) => {
              ele1.Parent_Id = this.selectedItem.ItemUniqueid;
    
              this.Addtocart(ele1, false);
              if (ele1.selectedBase != null) {
                ele1.selectedBase.forEach(base=>{
                  base.Parent_Id = this.selectedItem.ItemUniqueid;
                  this.Addtocart(base, false);
                })
              }
              if (ele1.selectedToppings != null) {
    
                ele1.selectedToppings.map((el) => {
                  el.Parent_Id = this.selectedItem.ItemUniqueid;
                  this.Addtocart(el, false);
    
                })
    
              }
            })
          })
        
       }
       else
       {
        ele.Item.map((eachEle) => {
          eachEle.LabelAdded++;
          eachEle.Parent_Id = this.selectedItem.ItemUniqueid;
          this.Addtocart(eachEle, false);
        })
       }

      })
    }
    

    this.HideMealModal();
    this.selectedItem = null;
    this.selectedBase = [];
    this.selectedToppings = [];
    this.MealDealItems = null;
    this.showCompleteMealDeal = false;
    this.selectedMealdealItem = null;


  }
  removeItemsFromDeal(item: MenuModel.Item) {
    if (this.selectedItem && this.selectedItem.LabelCount > 0) {
      this.selectedItem.LabelAdded--;
    }
    for (let index = 0; index < this.currentDeal.selectedItems.length; index++) {
      if (item.ItemUniqueid == this.currentDeal.selectedItems[index].ItemUniqueid) {
        this.currentDeal.selectedItems.splice(index, 1);
        this.currentDeal.Selected_Quantity--;
        break;
      }

    }
    this.ShowCompleteButton();
  }
  checkoutCart() {

    if (this.cl != undefined && this.cl.length > 0) {
      if (this.orderInstructions != "")
        this.AddInstructionToCart();
      if (!this.shopStatus.Shop_Status && this.shopStatus.PreOrder)
        localStorage.setItem("_cartMeta", JSON.stringify({ "shopPreorder": this.shopStatus.PreOrder, "OrderTime": this.selectedHour + ":" + this.selectedMin }));
      else if(this.shopStatus.Shop_Status && this.shopStatus.PreOrder)
      localStorage.setItem("_cartMeta", JSON.stringify({ "ScheduleOrder": this.shopStatus.PreOrder, "OrderTime": this.selectedHour + ":" + this.selectedMin }));
        else
        localStorage.removeItem("_cartMeta");
      this.route.navigate(['/checkout']);
    }
  }
  AddInstructionToCart() {
    if (this.orderInstructions != "") {
      this.newitem = new NewItem();
      this.newitem.Name = this.orderInstructions;
      this.newitem.Item_Id = 0;
      this.newitem.ItemType = "Cooking Instructions";
      this.Addtocart(this.newitem, false);
      this.orderInstructions = "";
    }
    else {
      document.getElementById('orderinstruction').focus();
    }
  }
  EnterInstructions(e) {

    if (e.keyCode == 13) {
      this.AddInstructionToCart();

    }

  }
  AddInstruction() {
    this.AddInstructionToCart();
  }
  toggleDiv(obj: MenuModel.Cat) {
    obj.height = (obj.height == "100%" ? "0" : "100%")
  }
  toggleDivOna(obj: MenuModel.Cat) {
    obj.height = (obj.height == "100%" ? "0" : "100%")
  }
}
