import { Component, Inject, OnInit } from '@angular/core';
import { SercallService} from '../sercall.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { CookieService} from '../cookie.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  cl=new Array();
  subtotal:number=0; 
  txtLoginEmailtext:string="";
  txtLoginPasstext:string="";
  showLoader:boolean=false;
  constructor(private se:SercallService,private cookieser:CookieService,@Inject(AppComponent) private AppCompoParent: AppComponent, private route:Router) { 

    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
  }

  ngOnInit() {
    this.LoadCart();
  }
  public removeSripts()
  {
    var arr=document.getElementsByTagName('script');
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].src.includes('scripts.js'))
      {
        arr[index].remove();
        break;
      }
    }
  }
  public loadScript(url: string) {
  const body = <HTMLDivElement> document.body;
   
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  body.appendChild(script);
  } 
  LoadCart()
  {
    this.subtotal=0
    this.cl = this.getCart();
    this.cl.map((ele)=>{

      this.subtotal+=parseFloat(ele.ItemTotal)
    })  
  }
  getCart()
  {
    var cart= localStorage.getItem('_cart')
    if(cart!=null&&cart!="")
     return JSON.parse(cart)
     else
     return [];


  }
  loginUser()
  { 
    this.showLoader=true;
       
      let body = new URLSearchParams();
      body.set('grant_type', "password");
      body.set('username', this.txtLoginEmailtext);
      body.set('password', this.txtLoginPasstext ); 
      this.se.PostDataWtihForm<LoginModel.AccessToken>("token",body.toString()).then(data=>{

        if(data.access_token!="")
        {
          this.AppCompoParent.loginSuccess=true;
          localStorage.setItem("AuthToken",data.access_token);
          localStorage.setItem("refresh_token",data.refresh_token);
          
          if(this.cl.length>0)
            this.route.navigate(['/checkout'])
            else
            this.route.navigate(['/MyAccount'])
            
        }
        else
        {
          this.errorMsg="";
          this.ClearMsgLog();
        }
        this.showLoader=false;
      }).catch(e=>{
        this.showLoader=false;
        this.errorMsg=e.error.error_description;
          this.ClearMsgLog();
      });
      
  }
  txtNametext:string="";
  txtPasswordtext:string="";
  txtConPassordtext:string="";
  txtPostCodetext:string="";
  txtMobiletext:string="";
  txtEmailtext:string="";
  errorMsgReg:string="";
  errorMsg:string="";
  RegisterUser()
  { 
    this.showLoader=true;
      var payload={
        "Key":"123",
        "Id":"0",
        "Cust_Name":this.txtNametext,
        "Add0":"",
        "Add1":"",
        "PostCode":this.txtPostCodetext,
        "Mobile":this.txtMobiletext,
        "E_Mail":this.txtEmailtext,
        "Password":this.txtPasswordtext
    }; 
    
      this.se.postdata<LoginModel.LoginResponseObject>("v1/api/user/sign-up",payload).then(data=>{

        if(data.ResponseCode==0)
        {
          this.txtLoginEmailtext=this.txtMobiletext;
          this.txtLoginPasstext=this.txtPasswordtext;
          
           this.loginUser();
           
        }
        else
        {
            this.errorMsgReg=data.ResponseMessage;
            this.ClearMsgReg();
        }
        this.showLoader=false;
      });
      
  }
  ClearMsgReg()
  {
    setTimeout(() => {
      this.errorMsgReg="";
    }, 2000);
  }
  ClearMsgLog()
  {
    setTimeout(() => {
      this.errorMsg="";
    }, 2000);
  }
}
