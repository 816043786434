import { Component, ElementRef, OnInit, Output ,ViewChild} from '@angular/core';
import { MetaService } from '../meta.service';
import { SercallService } from '../sercall.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AboutContent, AboutContentResp, Archievement, ArchievementResp, ContactUs, ContactUsResp, DealCourse, DealResponse, EnableTable, EnableTableResp, HomeBanner, HomeBannerResp, Item, MailResponseObject, MealDealResp, NewItem, ShopStatus, ShopTiming, ShopTimingResp, Testimonial, TestimonialResp } from '../StatusModel';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('recaptcha') recaptchaElement:  ElementRef;
  ContactForm:FormGroup;
  submitted:boolean=false;
  SuccessMsg:string="";
  disableBtn:boolean=false;
  // @Output() getMenuObj=new EventEmitter<any>();
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 30,
    width:350,
    dots: false,
    navSpeed: 700,
    stopOnHover:true,
    navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
   
  }
  cl = new Array();
  Bases = new Array<Item>();
  Toppings = new Array<Item>();
  MealDealItems = new Array<DealCourse>();
  UniqueId: number = 0;
  SelecteDeliv: number = 0;
  showModal: boolean = false;
  showMDModal: boolean = false;
  showLoader: boolean = false;
  selectedBase: Item[];
  selectedToppings = new Array<Item>();
  selectedItem: Item;
  selectedMealdealItem: Item;
  errorMsg: string = "";
  backDrop: boolean = false;
  MealdDealHeading: string = "";
  currentMealDeal: Array<DealCourse>;
  dealCourseCurrentIndex: number = 0;
  MealdSubheading: string = "";
  showCompleteMealDeal: boolean = false;
  totalMealdealQty: number = 0;
  currentDeal: DealCourse;
  orderInstructions: string = "";
  newitem: NewItem;
  shopStatus: any;
  currentTime: string = "";
  shopEnabled: boolean = true;
  hourlist = new Array();
  selectedHour: number;
  selectedMin: string = "00";
  hidden :boolean;
  static:boolean;
  shopStatus1: any;
  currentTime1: string = "0:00";
  type1:string ="";
  isDisabledState1: boolean = true;
  show:boolean = false;   
  labelText:string="";
  isDisabledAddButton:boolean=true;
  weburl:string="";
  constructor(private formbuilder:FormBuilder,private se:SercallService,private meta:MetaService, private AppCompoParent: AppComponent,private router: Router,private datePipe: DatePipe) {
    this.meta.updateTitle();
        this.removeSripts();
        this.loadScript('../assets/js/scripts.js');
  //       this.se.getdataWtihForm('http://testapi.sultanthornaby.com/token').then(data=>{
     
  // })
   }
  ngOnInit() {
    const firstTime = localStorage.getItem('ReloadHome');
    if(!firstTime){
      localStorage.setItem('ReloadHome','loaded');
      window.location.reload();
    }else {
      localStorage.removeItem('ReloadHome');
    }
    this.weburl=localStorage.getItem("weburl");
    this.ContactForm=this.formbuilder.group({
      Name:['',[Validators.required]],
      NoOfPerson:['',[Validators.required]], 
      Mobile:['',[Validators.required]],
      DateTime:['',[Validators.required]],
      Comment:['',[Validators.required]]
    });
    this.HomeBanner();
    this.LoadArchievement();
    this.LoadAboutContent();
    this.LoadTestimonials();
    this.LoadMealDeal();
    this.LoadEnableTable();
    this.addRecaptchaScript();
    this.LoadShopTimming();
  }
  shoptimming:ShopTiming[];
ShopTimingsforpreorder:ShopTiming[];
shopStatus2:ShopStatus;
LoadShopTimming()
{
  var date = new Date();
   
  var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":this.datePipe.transform(date,"yyyy-MM-dd HH:mm"),"IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Shop_Info"};
  this.se.postdata<LoginModel.CommonRreponse<ShopTimingResp>>('v1/api/get-set-information-more',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
        {
          this.ShopTimingsforpreorder=data.ResponseObject.ShopTimingsforpreorders;
          this.shoptimming=data.ResponseObject.ShopTimings;
          this.shopStatus2=data.ResponseObject.ShopStatus[0];
          localStorage.setItem("ShopStatus",JSON.stringify(this.shopStatus2));
          localStorage.setItem("ShopTimming",JSON.stringify(this.shoptimming));
          localStorage.setItem("ShopTimingsforpreorder",JSON.stringify(this.ShopTimingsforpreorder));
          this.LoadOpenClose();
        }
      }
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {
        this.LoadShopTimming();

      }
    }
  });

}
LoadOpenClose()
{
  this.shopStatus = JSON.parse(localStorage.getItem("ShopStatus"));
  var dat = new Date();
  if (this.shopStatus != undefined) {
  
    if (this.shopStatus.Shop_Status) {
    //
    if (this.shopStatus.PreOrder) {
      this.currentTime = this.datePipe.transform(dat, "hh:mm");
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
  
      var shoptimming = JSON.parse(localStorage.getItem("ShopTimingsforpreorder"));
      var shoptime = shoptimming.filter(function (t, u) {
        return t.Day_Name == days[dat.getDay()];
      })
      if (shoptime != undefined && shoptime.length > 0) {
        var regex = new RegExp(':', 'g');
        var from = shoptime[0].Opening_Time;
        var to = shoptime[0].Closing_Time;
        this.currentTime1=from;
        var CTime=this.datePipe.transform(dat, "HH:mm:ss");
  
        console.log(CTime);
        if(parseInt(CTime.replace(regex, ''), 10) < parseInt(from.replace(regex, ''), 10))
        {
            this.show = true;  
            this.labelText="Shop is closed now but you can preorder if you want."; 
            this.type1="Schedule";
            this.isDisabledState1=true;
            var fromhour = Number.parseInt(from.replace(':', '').substring(0, 2))
            var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
            tohour=Date.parse(tohour.toString());
            this.selectedHour = fromhour;
            // this.selectedMin=="05";
            for (let index = fromhour; index <= tohour-1; index++) {
              this.hourlist.push(index)
            }
            this.hourlist.pop();
  
        }
        else
        {
          this.type1="ASAP";
          this.isDisabledState1=false;
          var fromhour = Number.parseInt(CTime.replace(':', '').substring(0, 2))
          var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
          this.selectedHour = fromhour;
          if(this.selectedMin=="05")
                {
                  this.selectedMin=this.datePipe.transform(dat, "mm");
                }
          for (let index = fromhour; index <= tohour; index++) {
            this.hourlist.push(index)
          }
          this.hourlist.pop();
        }
        console.log(from.replace(':', '').substring(0, 4), to.replace(':', '').substring(0, 4));
  
      }
    }
      else {
        this.shopEnabled = false;
      }
  
    }
    else 
    {
      this.show = true;  
      this.labelText="Shop is now closed you cannot order."; 
      this.isDisabledAddButton=false;
      // Menu Button Disable, Message - Shop Is Closed 
    
    }
  }
}
  renderReCaptch() {  
    let SiteKey="";
  var arr = JSON.parse(localStorage.getItem('ContactContent'));
arr.forEach(function(obj) {
  SiteKey=(obj.Recaptcha_SiteKey);
});
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : SiteKey,
      'callback': (response) => {
          console.log(response);
      }
    });
  }
 
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

  get f() { return this.ContactForm.controls; }
  onSubmit() {
    this.submitted = true;
  
    // stop here if form is invalid
    if (this.ContactForm.invalid) {
        return;
    }
    this.disableBtn=true;
    this.showLoader=true;
    this.errorMsg="";
    
    var payload= {
      "Name":this.ContactForm.controls.Name.value,
      "Mobile":this.ContactForm.controls.Mobile.value,
      "email":this.ContactForm.controls.Email.value,
      "Subject":this.ContactForm.controls.Subject.value,
      "Msg":this.ContactForm.controls.Msg.value
  }
    this.se.postdata<LoginModel.CommonRreponse<MailResponseObject>>('v1/api/send-mail',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        this.SuccessMsg=data.ResponseObject.ShopStatus[0].Mail_Responce;
        this.submitted=false;
        this.ContactForm.markAsPristine();
        this.ContactForm.reset();
      }
      else
      {
        this.errorMsg=data.ResponseMessage;
        this.clearErrorMsg();
        //show error msg from server
      }
      this.disableBtn=false;
      this.showLoader=false;
    });
    
  }
  clearErrorMsg()
  {
  setTimeout(() => {
    this.errorMsg="";
  }, 2500);
  }  
  
  enabletable:EnableTable[];
  LoadEnableTable()
{
  var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
  this.se.postdata<LoginModel.CommonRreponse<EnableTableResp>>('v1/api/get-set-information-more',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
        {
          this.enabletable=data.ResponseObject.EnableRequestStatusBookingSetup;
          localStorage.setItem("EnableTable",JSON.stringify(this.enabletable));
          this.hidden = data.ResponseObject.EnableRequestStatusBookingSetup[0].Enable_RequestStatus_Booking;
        }
      }
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {
        this.LoadEnableTable();

      }
    }
  });
}
  homebanner:HomeBanner[];
  HomeBanner()
{
  var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
  this.se.postdata<LoginModel.CommonRreponse<HomeBannerResp>>('v1/api/get-set-information-more',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
        {
          this.homebanner=data.ResponseObject.HomeBanners;
          localStorage.setItem("HomeBanner",JSON.stringify(this.homebanner));
        }
      }
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {
        this.LoadAboutContent();

      }
    }
  });
}

archievement:Archievement[];
LoadArchievement()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<ArchievementResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.archievement=data.ResponseObject.Archievements;
        localStorage.setItem("Archievement",JSON.stringify(this.archievement));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadArchievement();

    }
  }
});
}
aboutcontent:AboutContent[];
LoadAboutContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<AboutContentResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.aboutcontent=data.ResponseObject.AboutContents;
        localStorage.setItem("AboutContent",JSON.stringify(this.aboutcontent));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadAboutContent();

    }
  }
});
}
testimonials:Testimonial[];
LoadTestimonials()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<TestimonialResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.testimonials=data.ResponseObject.Testimonials;
        localStorage.setItem("Testimonials",JSON.stringify(this.testimonials));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadAboutContent();

    }
  }
});
}
mealdeal:Item[];
LoadMealDeal()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<MealDealResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.mealdeal=data.ResponseObject.MealDeals;
        localStorage.setItem("MealDeals",JSON.stringify(this.mealdeal));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadMealDeal();

    }
  }
});
}
Addtocart(obj: MenuModel.Item, addbase: boolean) {

  if (obj == null)
    return;
  if (!this.shopEnabled)
    return;
  var cart = new Array();

  cart = this.getCart();
  var added = false
  var citem = Object.assign({}, obj);
  citem.Qty = 1;

  if (!obj.SizeExists) {
    citem.ItemTotal = citem.Qty * obj.Dilivery_Price;
    citem.CartName = citem.Name;
  }
  else {
    citem.ItemTotal = citem.Qty * obj.selectedSize.Dilivery_Price;
    citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
  }

  citem.UniqueId = this.UniqueId + 1;
  this.UniqueId = this.UniqueId + 1;

  if (citem.Is_Item != 4) {
    if (addbase) {
      this.selectedItem = citem;
      this.selectedItem.ItemUniqueid = Math.random().toString(16).slice(2);
    }
    cart.push(citem);


    if (addbase) {
      // if ((!citem.BaseExists) && (!citem.Auto_Show_Extras) && (!citem.ToppingsExists)) {
        if ((!citem.BaseExists) && (!citem.Auto_Show_Extras)) {
        if (citem.LabelCount > 0 && (citem.LabelCount > citem.LabelAdded)) {
          var payload = {
            "Key": "123",
            "Id": citem.Item_Id,
            "Size_Id": citem.Size_Id,
            "OrderType": "",
            "CmdType": "Get_Label_With_Details_On_ItemId"
          };
          this.showLoader = true;

          this.selectedBase = [];
          this.se.postdata<MenuModel.DealResponse>('v1/api/get-Label-Course', payload).then(data => {
            if (data.ResponseCode == 0) {

              this.totalMealdealQty = 0;
              let dealuniqueid: number = 0;
              this.dealCourseCurrentIndex = 0;
              this.currentMealDeal = data.ResponseObject.DealCourse;
              this.currentMealDeal.map((ele) => {
                ele.DealUniquId = ++dealuniqueid;
                ele.Item.map((el) => {

                  el.ItemUniqueid = Math.random().toString(16).slice(2);

                })
              })


              let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
                return mealEle.Is_Fix == false;
              });
              if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
                filteredMealdealItems[0].visible = true;
                this.MealdSubheading = filteredMealdealItems[0].Course_Name;
                filteredMealdealItems.map((filitems) => {
                  this.totalMealdealQty += filitems.Selection_Quantity;
                  filitems.Selected_Quantity = 0;
                  filitems.Item.map((ele) => {
                    ele.Qty = 0;
                    if (ele.SizeExists)
                      ele.selectedSize = ele.size[0];
                  });
                })

                this.MealDealItems = filteredMealdealItems;
                this.MealdDealHeading = citem.Name;

                this.showLoader = false;
                this.backDrop = true;
                this.showMDModal = true;
              }
              else {
                
                this.MealDealCompleted();
              }


            }
          });
        }
        else {
          localStorage.setItem('_cart', JSON.stringify(cart));
          this.loadCart()
        }
      }
      else {
        if(citem.SizeExists)
        {
           payload = {
            "Key": "123",
            "Id": citem.Item_Id,
            "Size_Id": citem.selectedSize.Size_Id,
            "OrderType": "",
            "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
          };
        }
        else{
          payload = {
           "Key": "123",
           "Id": citem.Item_Id,
           "Size_Id": citem.Size_Id,
           "OrderType": "",
           "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
         };
        }
        this.showLoader = true;

        this.selectedBase = [];
        this.se.postdata<BaseModel.MainReponse>('v1/api/get-base-toppings', payload).then(data => {


          this.Bases = data.ResponseObject.Base;
          this.Toppings = data.ResponseObject.Topping;



          this.Bases.forEach(element => {
            element.ItemUniqueid = Math.random().toString(16).slice(2);
            if (this.SelecteDeliv == 0) {
              element.ShowPrice_Price = element.Dilivery_Price;
              element.Multiselection_Base = citem.Multiselection_Base;
            }
            else
              element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
              element.Multiselection_Base = citem.Multiselection_Base;
          });
          this.Toppings.forEach(element => {
            element.ItemUniqueid = Math.random().toString(16).slice(2);
            if (this.SelecteDeliv == 0) {
              element.ShowPrice_Price = element.Dilivery_Price;
              element.Multiselection_Base = citem.Multiselection_Base;
            }
            else
              element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
              element.Multiselection_Extras = citem.Multiselection_Extras;
          });
          this.showLoader = false;
          this.selectedToppings = new Array<MenuModel.Item>();
          this.ShowModal();
        })

      }
    }
    else {
      if (citem.LabelCount > 0 && (citem.LabelCount > citem.LabelAdded)) {
        var LabeId="";
      if(this.selectedToppings.length>0)
      {
        
        LabeId=this.selectedItem.Item_Id.toString();
        for (let index = 0; index < this.selectedToppings.length; index++) {
          if(this.selectedToppings[index].LabelCount>0)
          {
            LabeId=LabeId+","+this.selectedToppings[index].Item_Id.toString();
            
          }
        }
      }
        // var payload = {
        //   "Key": "123",
        //   "Id": citem.Item_Id,
        //   "Size_Id": citem.Size_Id,
        //   "OrderType": "",
        //   "CmdType": "Get_Label_With_Details_On_ItemId"
        // };
        var payload = {
          "Key": "123",
          "Id": citem.Item_Id,
          "Size_Id": citem.Size_Id,
          "OrderType": LabeId,
          "CmdType": "Get_Label_With_Details_On_ItemId_Multiple"
        };
        this.showLoader = true;

        this.selectedBase = [];
        this.se.postdata<MenuModel.DealResponse>('v1/api/get-Label-Course', payload).then(data => {
          if (data.ResponseCode == 0) {

            this.totalMealdealQty = 0;
            let dealuniqueid: number = 0;
            this.dealCourseCurrentIndex = 0;
            this.currentMealDeal = data.ResponseObject.DealCourse;
            this.currentMealDeal.map((ele) => {
              ele.DealUniquId = ++dealuniqueid;
              ele.Item.map((el) => {

                el.ItemUniqueid = Math.random().toString(16).slice(2);

              })
            })

            let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
              return mealEle.Is_Fix == false;
            });
            if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
              filteredMealdealItems[0].visible = true;
              this.MealdSubheading = filteredMealdealItems[0].Course_Name;
              filteredMealdealItems.map((filitems) => {
                this.totalMealdealQty += filitems.Selection_Quantity;
                filitems.Selected_Quantity = 0;
                filitems.Item.map((ele) => {
                  ele.Qty = 0;
                  if (ele.SizeExists)
                    ele.selectedSize = ele.size[0];
                });
              })

              this.MealDealItems = filteredMealdealItems;
              this.MealdDealHeading = citem.Name;

              this.showLoader = false;
              this.backDrop = true;
              this.showMDModal = true;
            }
            else {
              this.MealDealCompleted();
            }


          }
        });
      }
      else {
        localStorage.setItem('_cart', JSON.stringify(cart));
        this.loadCart()
      }
    }
  }
  else {
    if (addbase) {
      this.selectedItem = citem;
      this.selectedItem.ItemUniqueid = Math.random().toString(16).slice(2);
    }
    cart.push(citem);
    if (addbase) {
      //4 item type
      var Dealpayload = {
        "Key": "123",
        "Id": citem.Item_Id,
        "Size_Id": "0",
        "OrderType": "",
        "CmdType": "Get_Course_With_Details_On_DealId"
      }
      this.showLoader = true;
      this.se.postdata<MenuModel.DealResponse>('v1/api/get-Deal-Course', Dealpayload).then(data => {
        if (data.ResponseCode == 0) {

          this.totalMealdealQty = 0;
          let dealuniqueid: number = 0;
          this.dealCourseCurrentIndex = 0;
          this.currentMealDeal = data.ResponseObject.DealCourse;
          this.currentMealDeal.map((ele) => {
            ele.DealUniquId = ++dealuniqueid;
            ele.Item.map((el) => {

              el.ItemUniqueid = Math.random().toString(16).slice(2);

            })
          })

          let filteredMealdealItems = this.currentMealDeal.filter((mealEle) => {
            return mealEle.Is_Fix == false;
          });
          if (filteredMealdealItems != undefined && filteredMealdealItems.length > 0) {
            filteredMealdealItems[0].visible = true;
            this.MealdSubheading = filteredMealdealItems[0].Course_Name;
            filteredMealdealItems.map((filitems) => {
              this.totalMealdealQty += filitems.Selection_Quantity;
              filitems.Selected_Quantity = 0;
              filitems.Item.map((ele) => {
                ele.Qty = 0;
                if (ele.SizeExists)
                  ele.selectedSize = ele.size[0];
              });
            })

            this.MealDealItems = filteredMealdealItems;
            this.MealdDealHeading = citem.Name;

            this.showLoader = false;
            this.backDrop = true;
            this.showMDModal = true;
          }


        }
      });
    }
    else {
      localStorage.setItem('_cart', JSON.stringify(cart));
      this.loadCart()

    }

  }

  return false;
}
public ShowModal() {
  this.showModal = true;
  this.backDrop = true;
}
HideModal() {
  this.showModal = false;
  if (this.showModal == false && this.showMDModal == false)
    this.backDrop = false;
}
HideMealModal() {
  this.showMDModal = false;
  if (this.showModal == false && this.showMDModal == false)
    this.backDrop = false;
}
SelectBase(obj: MenuModel.Item,e,type) {
  if(type=='single')
  {
    this.selectedBase=[];
  obj.Parent_Id = this.selectedItem.ItemUniqueid
  obj.SizeExists = false;
  obj.BaseExists = false;
  obj.ToppingsExists = false;
  obj.LabelAdded=0;
  this.selectedBase.push(obj);
  }
  else
  {
  if (e.target.checked) {
    obj.Parent_Id = this.selectedItem.ItemUniqueid
    obj.SizeExists = false;
    obj.BaseExists = false;
    obj.ToppingsExists = false;
    obj.LabelAdded=0;
    this.selectedBase.push(obj);
  }
  else {
    this.selectedBase.forEach((ele, i) => {
      if (ele.Price_Id == obj.Price_Id) {
        delete this.selectedBase[i];
      }

    });
  }
}
}
SelectToppings(obj: MenuModel.Item, e,type) {
  if(type=='single')
  {
    this.selectedToppings=[];
  obj.Parent_Id = this.selectedItem.ItemUniqueid
  obj.SizeExists = false;
  obj.BaseExists = false;
  obj.ToppingsExists = false;
  obj.LabelAdded=0;
  this.selectedToppings.push(obj);
  }
  else
  {
  if (e.target.checked) {
    obj.Parent_Id = this.selectedItem.ItemUniqueid
    obj.SizeExists = false;
    obj.BaseExists = false;
    obj.ToppingsExists = false;
    obj.LabelAdded=0;
    this.selectedToppings.push(obj);
  }
  else {
    this.selectedToppings.forEach((ele, i) => {
      if (ele.Price_Id == obj.Price_Id) {
        delete this.selectedToppings[i];
      }

    });
  }
}
}
getCart() {
  var cart = localStorage.getItem('_cart')
  if (cart != null && cart != "")
    return JSON.parse(cart)
  else
    return [];


}
AddtocartExplicit(obj:MenuModel.Item)
{
  var cart = new Array();

  cart = this.getCart();
  var added = false
  var citem = Object.assign({}, obj);
  citem.Qty = 1;

  if (!obj.SizeExists) {
    citem.ItemTotal = citem.Qty * obj.Dilivery_Price;
    citem.CartName = citem.Name;
  }
  else {
    citem.ItemTotal = citem.Qty * obj.selectedSize.Dilivery_Price;
    citem.CartName = citem.Name + ' - ' + citem.selectedSize.Name
  }

  citem.UniqueId = this.UniqueId + 1;
  this.UniqueId = this.UniqueId + 1;

  
   
    cart.push(citem);
    localStorage.setItem('_cart', JSON.stringify(cart));
    this.loadCart();

}
loadCart() {
  this.showLoader = true;
  this.subtotal = 0
  this.cl = this.getCart();
  if (this.cl.length > 0) {
    this.cartempty = false
    for (var i = 0; i < this.cl.length; i++) {
      if (this.SelecteDeliv == 0) {
        if (this.cl[i].selectedSize != null)
          this.cl[i].ItemTotal = (this.cl[i].selectedSize.Dilivery_Price * this.cl[i].Qty);
        else
          this.cl[i].ItemTotal = (this.cl[i].Dilivery_Price * this.cl[i].Qty);

      }
      else {
        if (this.cl[i].selectedSize != null)
          this.cl[i].ItemTotal = (this.cl[i].selectedSize.Takeaway_Collection_Bar_Price * this.cl[i].Qty);
        else
          this.cl[i].ItemTotal = (this.cl[i].Takeaway_Collection_Bar_Price * this.cl[i].Qty);
      }
      if (this.cl[i].Parent_Id != undefined) {
        this.cl[i].CartName = '-' + this.cl[i].Name;
        if (this.cl[i].selectedSize != null)
          this.cl[i].CartName = '-' + this.cl[i].Name + '-' + this.cl[i].selectedSize.Name;
      }
      this.subtotal += this.cl[i].ItemTotal;
    }
  }
  else {
    this.cartempty = true
    this.UniqueId = 0;
  }
  this.AppCompoParent.loadCart();
  this.showLoader = false;

}
AddBaseToppings() {
  if (this.selectedMealdealItem == null) {

    if (this.selectedItem.LabelCount == 0) {
      this.Addtocart(this.selectedItem, false);
      if (this.selectedBase != null) {
        // this.selectedBase.Name='  -'+this.selectedBase.Name
        this.selectedBase.forEach(base=>{
          this.Addtocart(base, false);
        });
      }
      if (this.selectedItem.Auto_Add_Free_Toppings) {
        var freetoppings = this.selectedItem.Free_Toppings_Ids.split(',');
        freetoppings.forEach(element => {
          this.Toppings.filter(function (ele) {
            return ele.Item_Id == parseInt(element);
          }).forEach(element1 => {
            let cele = Object.assign({}, element1);
            cele.Parent_Id = this.selectedItem.ItemUniqueid
            cele.SizeExists = false;
            cele.BaseExists = false;
            cele.ToppingsExists = false;
            cele.DineIn_Price = 0;
            cele.Dilivery_Price = 0;
            cele.Takeaway_Collection_Bar_Price = 0;
            cele.ThirdParty_Price = 0;
            cele.MD_Price = 0;
            // cele.Name='  -'+cele.Name           
            this.Addtocart(cele, false);
          });
        });

      }

      let freeqty = 0;
      this.selectedToppings.forEach(ele => {
        let cele = Object.assign({}, ele);
        if (this.selectedItem.Free_Toppings_Qty > 0) {
          let freetoppingsids = this.selectedItem.Free_Toppings_Ids.split(',');
          let freetoppingexist = false;
          let checkarray = freetoppingsids.filter((fele) => {
            return cele.Item_Id == parseInt(fele);
          });
          if (checkarray.length > 0)
            freetoppingexist = true;

          if (freeqty < this.selectedItem.Free_Toppings_Qty && freetoppingexist) {
            cele.DineIn_Price = 0;
            cele.Dilivery_Price = 0;
            cele.Takeaway_Collection_Bar_Price = 0;
            cele.ThirdParty_Price = 0;
            cele.MD_Price = 0;
            freeqty++;
          }

        }
        //cele.Name='  -'+cele.Name
        this.Addtocart(cele, false);
      });


    }
    else {
      this.Addtocart(this.selectedItem, false);
    }
    this.HideModal();
  }
  else {

    this.selectedMealdealItem.selectedBase = [];
    this.selectedMealdealItem.selectedToppings = new Array<MenuModel.Item>();
    if (this.selectedBase != null) {
      //this.selectedBase.Name='  -'+this.selectedBase.Name
      this.selectedMealdealItem.selectedBase = this.selectedBase;
    }
    if (this.selectedMealdealItem.Auto_Add_Free_Toppings) {
      var freetoppings = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
      freetoppings.forEach(element => {
        this.Toppings.filter(function (ele) {
          return ele.Item_Id == parseInt(element);
        }).forEach(element1 => {
          let cele = Object.assign({}, element1);
          cele.Parent_Id = this.selectedMealdealItem.ItemUniqueid
          cele.SizeExists = false;
          cele.BaseExists = false;
          cele.ToppingsExists = false;
          cele.DineIn_Price = 0;
          cele.Dilivery_Price = 0;
          cele.Takeaway_Collection_Bar_Price = 0;
          cele.ThirdParty_Price = 0;
          cele.MD_Price = 0;
          //cele.Name='  -'+cele.Name           
          this.selectedMealdealItem.selectedToppings.push(cele);
        });
      });

    }

    let freeqty = 0;
    this.selectedToppings.forEach(ele => {
      let cele = Object.assign({}, ele);
      
      let freetoppingsids = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
      let checkarray1 = freetoppingsids.filter((fele) => {
        if (freeqty < this.currentDeal.Extras_Quantity && cele.Item_Id == parseInt(fele)) {
          cele.DineIn_Price = 0;
          cele.Dilivery_Price = 0;
          cele.Takeaway_Collection_Bar_Price = 0;
          cele.ThirdParty_Price = 0;
          cele.MD_Price = 0;
          freeqty++;
        }
      });
      if (this.selectedMealdealItem.Free_Toppings_Qty > 0) {
        let freetoppingsids = this.selectedMealdealItem.Free_Toppings_Ids.split(',');
        let freetoppingexist = false;
        let checkarray = freetoppingsids.filter((fele) => {
          return cele.Item_Id == parseInt(fele);
        });
        if (checkarray.length > 0)
          freetoppingexist = true;

        if (freeqty < this.selectedMealdealItem.Free_Toppings_Qty && freetoppingexist) {
          cele.DineIn_Price = 0;
          cele.Dilivery_Price = 0;
          cele.Takeaway_Collection_Bar_Price = 0;
          cele.ThirdParty_Price = 0;
          cele.MD_Price = 0;
          freeqty++;
        }

      }
      //cele.Name='  -'+cele.Name
      this.selectedMealdealItem.selectedToppings.push(cele);

    });
    if (this.currentDeal.selectedItems == undefined)
      this.currentDeal.selectedItems = new Array<MenuModel.Item>();
    this.currentDeal.selectedItems.push(Object.assign({}, this.selectedMealdealItem));
    this.selectedMealdealItem.Qty++;
    this.currentDeal.Selected_Quantity++;
    this.ShowCompleteButton();
    this.HideModal();

  }
}
ClearErrorMsg() {
  setTimeout(() => {
    this.errorMsg = "";
  }, 2000);
}
AddtocartMeal(citem: MenuModel.Item, deal: MenuModel.DealCourse, addbase: boolean, addRemo: string) {
  this.currentDeal = deal;
  if (this.selectedItem && this.selectedItem.LabelCount > 0) {
    this.selectedItem.LabelAdded++;
  }
  if (deal.Selected_Quantity >= deal.Selection_Quantity && addRemo == "add") {
    this.ShowDealCourseWithIndex('next');
    return false;
  }
  if (deal.Selected_Quantity == 0 && addRemo == "remove" && citem.Qty == 0)
    return false;

  if (addRemo == "add") {
    if (!citem.BaseExists) {
      citem.Qty++;
      deal.Selected_Quantity++;
      if (this.currentDeal.selectedItems == undefined)
        this.currentDeal.selectedItems = new Array<MenuModel.Item>();
      this.currentDeal.selectedItems.push(Object.assign({}, citem));

    }
    else {
      citem.ItemUniqueid = Math.random().toString(16).slice(2);
      this.selectedMealdealItem = citem;
      var payload = {
        "Key": "123",
        "Id": citem.Item_Id,
        "Size_Id": citem.Size_Id,
        "OrderType": "",
        "CmdType": "Get_Base_Toppings_On_ItemId_SizeId"
      };
      this.showLoader = true;

      this.selectedBase = [];
      this.se.postdata<BaseModel.MainReponse>('v1/api/get-base-toppings', payload).then(data => {


        this.Bases = data.ResponseObject.Base;
        this.Toppings = data.ResponseObject.Topping;



        this.Bases.forEach(element => {
          element.ItemUniqueid = Math.random().toString(16).slice(2);
          if (this.SelecteDeliv == 0) {
            element.ShowPrice_Price = element.Dilivery_Price;
            element.Multiselection_Base = citem.Multiselection_Base;
          }
          else
            element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
            element.Multiselection_Base = citem.Multiselection_Base;
        });
        this.Toppings.forEach(element => {
          element.ItemUniqueid = Math.random().toString(16).slice(2);
          if (this.SelecteDeliv == 0) {
            element.ShowPrice_Price = element.Dilivery_Price;
            element.Multiselection_Extras=citem.Multiselection_Extras;
          }
          else
            element.ShowPrice_Price = element.Takeaway_Collection_Bar_Price;
            element.Multiselection_Extras=citem.Multiselection_Extras;
        });
        this.showLoader = false;
        this.selectedToppings = new Array<MenuModel.Item>();
        this.ShowModal();
      })

    }

  }
  if (deal.Selected_Quantity >= deal.Selection_Quantity && addRemo == "add") {
    this.ShowDealCourseWithIndex('next');

  }
  this.ShowCompleteButton();


}
ShowCompleteButton() {
  let selectedTotalItems: number = 0;
  this.MealDealItems.map((ele) => {
    selectedTotalItems += ele.Selected_Quantity;
  })
  this.showCompleteMealDeal = (selectedTotalItems == this.totalMealdealQty);
}
ShowDealCourseWithIndex(type: string) {



  if (this.dealCourseCurrentIndex == 0 && type == "prev")
    return false;
  if (this.dealCourseCurrentIndex == this.MealDealItems.length - 1 && type == "next") {
    return false;

  }
  if (type == "next") {
    this.dealCourseCurrentIndex++;
  }
  else
    this.dealCourseCurrentIndex--;



  for (let index = 0; index < this.MealDealItems.length; index++) {
    const element = this.MealDealItems[index];
    element.visible = false;
  }
  this.MealDealItems[this.dealCourseCurrentIndex].visible = true;
  this.MealdSubheading = this.MealDealItems[this.dealCourseCurrentIndex].Course_Name;
  this.currentDeal = this.MealDealItems[this.dealCourseCurrentIndex];

}
MealDealCompleted() {

  if (this.selectedItem == null)
    return;
    this.AddtocartExplicit(this.selectedItem);
  if (this.selectedItem && this.selectedItem.BaseExists && this.selectedBase != null) {
    // this.selectedBase.Name='  -'+this.selectedBase.Name
    this.selectedBase.forEach(base=>{
      this.Addtocart(base, false);
    })
  }
  if (this.selectedItem.Auto_Add_Free_Toppings) {
    var freetoppings = this.selectedItem.Free_Toppings_Ids.split(',');
    freetoppings.forEach(element => {
      this.Toppings.filter(function (ele) {
        return ele.Item_Id == parseInt(element);
      }).forEach(element1 => {
        let cele = Object.assign({}, element1);
        cele.Parent_Id = this.selectedItem.ItemUniqueid
        cele.SizeExists = false;
        cele.BaseExists = false;
        cele.ToppingsExists = false;
        cele.DineIn_Price = 0;
        cele.Dilivery_Price = 0;
        cele.Takeaway_Collection_Bar_Price = 0;
        cele.ThirdParty_Price = 0;
        cele.MD_Price = 0;
        // cele.Name='  -'+cele.Name           
        this.Addtocart(cele, false);
      });
    });

  }

  let freeqty = 0;
  if (this.selectedItem && this.selectedItem.ToppingsExists && this.selectedToppings != null) {
  this.selectedToppings.forEach(ele => {
    let cele = Object.assign({}, ele);
    if (this.selectedItem.Free_Toppings_Qty > 0) {
      let freetoppingsids = this.selectedItem.Free_Toppings_Ids.split(',');
      let freetoppingexist = false;
      let checkarray = freetoppingsids.filter((fele) => {
        return cele.Item_Id == parseInt(fele);
      });
      if (checkarray.length > 0)
        freetoppingexist = true;

      if (freeqty < this.selectedItem.Free_Toppings_Qty && freetoppingexist) {
        cele.DineIn_Price = 0;
        cele.Dilivery_Price = 0;
        cele.Takeaway_Collection_Bar_Price = 0;
        cele.ThirdParty_Price = 0;
        cele.MD_Price = 0;
        freeqty++;
      }

    }
    //cele.Name='  -'+cele.Name
    this.Addtocart(cele, false);
  });
}
  if (this.MealDealItems && this.MealDealItems.length > 0)
  {
    
    
    this.currentMealDeal.map((ele) => {
      if(!ele.Is_Fix)
      {
        let filteredNonFix=this.MealDealItems.filter((w)=>{
          return w.DealUniquId==ele.DealUniquId;
        });
        filteredNonFix.map((selNonFix)=>{

          selNonFix.selectedItems.map((ele1) => {
            ele1.Parent_Id = this.selectedItem.ItemUniqueid;
  
            this.Addtocart(ele1, false);
            if (ele1.selectedBase != null) {
              ele1.selectedBase.forEach(base=>{
                base.Parent_Id = this.selectedItem.ItemUniqueid;
                this.Addtocart(base, false);
              })
            }
            if (ele1.selectedToppings != null) {
  
              ele1.selectedToppings.map((el) => {
                el.Parent_Id = this.selectedItem.ItemUniqueid;
                this.Addtocart(el, false);
  
              })
  
            }
          })
        })
      
     }
     else
     {
      ele.Item.map((eachEle) => {
        eachEle.LabelAdded++;
        eachEle.Parent_Id = this.selectedItem.ItemUniqueid;
        this.Addtocart(eachEle, false);
      })
     }

    })
  }
  

  this.HideMealModal();
  this.selectedItem = null;
  this.selectedBase = [];
  this.selectedToppings = [];
  this.MealDealItems = null;
  this.showCompleteMealDeal = false;
  this.selectedMealdealItem = null;


}
removeItemsFromDeal(item: Item) {
  if (this.selectedItem && this.selectedItem.LabelCount > 0) {
    this.selectedItem.LabelAdded--;
  }
  for (let index = 0; index < this.currentDeal.selectedItems.length; index++) {
    if (item.ItemUniqueid == this.currentDeal.selectedItems[index].ItemUniqueid) {
      this.currentDeal.selectedItems.splice(index, 1);
      this.currentDeal.Selected_Quantity--;
      break;
    }

  }
  this.ShowCompleteButton();
}
subtotal = 0;
cartempty = true
  public removeSripts()
  {
      var arr=document.getElementsByTagName('script');
      for (let index = 0; index < arr.length; index++) {
        if(arr[index].src.includes('scripts.js'))
        {
          arr[index].remove();
          break;
        }
      }
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
     
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  } 
}
