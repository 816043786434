import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SercallService } from '../sercall.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteAccount, Order, OrderDetail, OrderDetailResp, StatusModel, UpdatePassword } from '../StatusModel';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
  ProfileModel:LoginModel.LoginRespponse;
  UpdatePasswordModel:UpdatePassword;
  DeleteAccountModel:DeleteAccount;
  orderHistory=new Array<LoginModel.OrderHistroy>();
  ProfileForm:FormGroup;
  UpdatePasswordForm:FormGroup;
   DeleteAccountForm:FormGroup;
  submitted:boolean=false;
  showLoader:boolean=false;
  errorMsg:string="";
  disableBtn:boolean=false;
  orderDetails=new Array<OrderDetail>();
  order=new Order();
  showOrder:boolean=false;
  constructor(private se:SercallService,private  formbuilder:FormBuilder,private route:Router) { }

  ngOnInit() { 
    this.ProfileForm=this.formbuilder.group({
      Cust_Name:['',[Validators.required]],
      E_Mail:['',[]], 
      Mobile:['',[Validators.required]],
      Add0:['',[Validators.required]], 
      Add1:['',[Validators.required]],
      PostCode:['',[Validators.required]]
    });
    this.UpdatePasswordForm=this.formbuilder.group({
      Old_Password:['',[Validators.required]],
      New_Password:['',[Validators.required]], 
      Confirm_Password:['',[Validators.required]] 
    });
    this.DeleteAccountForm=this.formbuilder.group({
      Old_Password:['',[Validators.required]],
      Confirm_Password:['',[Validators.required]] 
    });

     
    this.LoadProfile();
    this.LoadOrders();
  }
  LoadProfile()
  { 
    this.showLoader=true;
    this.se.getdata<LoginModel.LoginResponseObject>('v1/api/user/get-profile').then(data=>{
      
      this.ProfileModel=data.ResponseObject;
        this.ProfileForm.controls.Cust_Name.setValue(this.ProfileModel.Cust_Name);
        this.ProfileForm.controls.E_Mail.setValue(this.ProfileModel.E_Mail);
        this.ProfileForm.controls.Mobile.setValue(this.ProfileModel.Mobile);
        this.ProfileForm.controls.Add0.setValue(this.ProfileModel.Add0);
        this.ProfileForm.controls.Add1.setValue(this.ProfileModel.Add1);
        this.ProfileForm.controls.PostCode.setValue(this.ProfileModel.PostCode);
        this.showLoader=false;
    }).catch(e=>{
        
      if(e.status==401)
      {
        if(this.se.CheckRefreshToken())
        {
          this.LoadProfile();

        }
      }
    })
  }
  LoadOrders()
  { 
    
    this.se.postdata<LoginModel.CommonRreponse<LoginModel.OrderHistroyList>>('v1/api/user/get-order-history',"").then(data=>{
       if(data.ResponseCode==0)
       {
        this.orderHistory=data.ResponseObject.OrderHistory;
        
       }
    }).catch(e=>{
        
      if(e.status==401)
      {
        if(this.se.CheckRefreshToken())
        {
          this.LoadOrders();

        }
      }
    })
  }
  get f() { return this.ProfileForm.controls; }
  get fu() { return this.UpdatePasswordForm.controls; }
  get fun() { return this.DeleteAccountForm.controls; }
  onProfileSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.ProfileForm.invalid) {
        return;
    }
    this.showLoader=true;
    // this.disableBtn=true;
    // this.showLoader=true;
    //this.errorMsg="";
    this.ProfileModel.Cust_Name=this.ProfileForm.controls.Cust_Name.value; 
    this.ProfileModel.E_Mail=this.ProfileForm.controls.E_Mail.value; 
    this.ProfileModel.Mobile=this.ProfileForm.controls.Mobile.value; 
    this.ProfileModel.Add0=this.ProfileForm.controls.Add0.value; 
    this.ProfileModel.Add1=this.ProfileForm.controls.Add1.value; 
    this.ProfileModel.PostCode=this.ProfileForm.controls.PostCode.value; 
      
    this.se.postdata<LoginModel.LoginResponseObject>('v1/api/user/update-profile',this.ProfileModel).then(data=>{
      if(data.ResponseCode==0)
      {
        this.clearErrorMsg(data.ResponseMessage)
         this.LoadProfile();
      }
      else
      {
         
        //show error msg from server
      }
      this.showLoader=false;
      this.submitted=false;
      // this.disableBtn=false;
      // this.showLoader=false;
    });
    
}
onUpdPassSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.UpdatePasswordForm.invalid) {
      return;
  }
  this.showLoader=true;
  // this.disableBtn=true;
  this.showLoader=true;
  this.errorMsg=""; 
  this.UpdatePasswordModel=new UpdatePassword();
  this.UpdatePasswordModel.Old_Password=this.UpdatePasswordForm.controls.Old_Password.value;
  this.UpdatePasswordModel.New_Password=this.UpdatePasswordForm.controls.New_Password.value;
    
  this.se.postdata<LoginModel.CommonRreponse<string>>('v1/api/user/update-password',this.UpdatePasswordModel).then(data=>{
    if(data.ResponseCode==0)
    {  
      this.clearErrorMsg(data.ResponseObject)
    }
    else
    {
      this.clearErrorMsg(data.ResponseObject)
      //show error msg from server
    }
    this.showLoader=false;
    // this.disableBtn=false;
    // this.showLoader=false;
  }).catch(e=>{
    this.showLoader=false;
    this.clearErrorMsg(e.error.ResponseObject)
  });
  
}
onDeleteSubmit() {
  this.submitted = true;
   // stop here if form is invalid
   if (this.DeleteAccountForm.invalid) {
    return;
}
this.showLoader=true;
// this.disableBtn=true;
this.showLoader=true;
this.errorMsg=""; 
this.DeleteAccountModel=new DeleteAccount();
this.DeleteAccountModel.Old_Password=this.DeleteAccountForm.controls.Old_Password.value;
  this.se.postdata<LoginModel.CommonRreponse<string>>('v1/api/user/delete-account',this.DeleteAccountModel).then(data=>{
    if(data.ResponseCode==0)
    {  
      this.clearErrorMsg(data.ResponseObject)
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("_cart");
      this.route.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    else
    {
      this.clearErrorMsg(data.ResponseObject)
      //show error msg from server
    }
    this.showLoader=false;
    // this.disableBtn=false;
    // this.showLoader=false;
  }).catch(e=>{
    this.showLoader=false;
    this.clearErrorMsg(e.error.ResponseObject)
  });
  
}
clearErrorMsg(msg:string)
{
  this.errorMsg=msg;
  setTimeout(() => {
    this.errorMsg="";
  }, 2500);
}
GetOrderDetails(Orderid:string)
{
  this.showOrder=false;
  this.orderDetails=new Array<OrderDetail>();
  var payload = {
  "StrFeild1": Orderid,
  "StrFeild2": "",
  "StrFeild3": "",
  "StrFeild4": "",
  "IntFeild1": 0,
  "IntFeild2": 0,
  "DecimalFeild1": 0,
  "DecimalFeild2": 0,
  "CMDTYPE": "Get_Web_Order_Details_On_Order_Id"
};

this.showLoader=true;
this.se.postdata<LoginModel.CommonRreponse<OrderDetailResp>>('v1/api/get-set-information-more', payload)
  .then(data => {
    
    if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
    {
      this.orderDetails=data.ResponseObject.OrderDetails;
      this.order=data.ResponseObject.Order[0];
      
       if(this.order.Discount==null)
       {
         this.order.Discount=0;
       }
       this.showOrder=true;

    }
    else{
      
     // this.errorMsgCoupon=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
    }
    
    this.showLoader=false;
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {
        this.GetOrderDetails(Orderid);

      }
    }
  });

}
}
