import { Component, OnInit, ViewChild } from '@angular/core';
import{Router,ActivatedRoute,ParamMap } from '@angular/router'
import { SercallService} from '../sercall.service';
import { StatusModel } from '../StatusModel';
import { CartComponent } from '../cart/cart.component';   
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'] 
   
})
export class ItemComponent implements OnInit {

  showModal:boolean=false;
  searchText:string="";
title:string="";
content:string="";
  @ViewChild(CartComponent ) child: CartComponent ; 
  l=new Array()
  Category_Name=''
  catid=0
  itemloaded=false
  constructor(private route: ActivatedRoute,private se:SercallService) {
    this.searchText="";
    this.route.params.subscribe( params => {console.log(params)
      this.Category_Name=params["catname"]
      this.catid=params["catid"]
      this.itemloaded=false
      this.se.getdata<StatusModel>('http://localhost:81/api/Values/getitems?catid='+this.catid).then(data=>{
        //console.log(data)
        this.l=data.Data 
        this.itemloaded=true
              })

    }
    
    );
   
  }
   
  ngOnInit() {

    
     
  }
  
  getCart()
  {
    var cart= localStorage.getItem('_cart')
    if(cart!=null&&cart!="")
     return JSON.parse(cart)
     else
     return [];


  }
   filarra=new Array();
  filterdata(obj)
  { 
  }
  Addtocart(obj) { 
var cart=new Array();
console.log(obj)
      cart=this.getCart();
    var added=false
    for(var i=0;i<cart.length;i++)
    {
      if(cart[i].itemid==obj.Id)
      {
        cart[i].qty=cart[i].qty+1
        added=true;
      }
      
    }
    if(!added)
    {
      cart.push({itemid:obj.Id,qty:1,img:obj.Main_Image,itemname:obj.Service_Product_Package_Name,price:obj.Customer_Price,taxper:obj.Tax_Percentage})
    }

    localStorage.setItem('_cart',JSON.stringify(cart))
    this.child.loadCart()
    this.title="Message Dialog";
    this.content="Item Added in cart";
    this.showModal=true;
  setTimeout(() => {
    this.showModal=false;
  },500); 
    return false;
  }
  hide(){
    this.showModal=false;
    return false;
  }

}
