import { Component, ElementRef,HostListener, Inject, NgModule, ViewChild } from '@angular/core'; 
//import {testCompnent} from './test/test.component'; 
import { DynamicScriptLoaderService } from './dynamic-script-loader.service'; 
import { CookieService} from './cookie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { CommunicationServiceService } from './communication-service.service';
import { SercallService } from './sercall.service';
import { ContactUs, ContactUsResp, HomeBannerResp, LogoAndContent, LogoAndContentResp, PaymentGatewayContent, PaymentGatewayContentResp, ShopStatus, ShopTiming, ShopTimingResp, SocialMedia, SocialMediaResp, StatusModel } from './StatusModel';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common'; 
import { MetaService } from './meta.service';
declare var $:any;
$("#installApp").hide();
setTimeout(function() { $("#installApp").show(); }, 31000);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent {
 
  deferredPrompt:any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  shopStatus1: any;
  currentTime: string = "";
  currentTime1: string = "0:00";
  shopEnabled: boolean = true;
  hourlist = new Array();
  minutelist = new Array();
  selectedHour: number;
  selectedMin: string = "00";
  type1:string ="";
  isDisabledState1: boolean = true;
  show:boolean = false;   
  labelText:string="";
  isDisabledAddButton:boolean=true;
  Gateway_Type:string ="";
  Public_Key:string ="";
  Client_Id:string ="";
  public yes: boolean = false;
  public no: boolean = false;
  public green: string = 'green';
  public gray: string = 'gray';
  public disabled: boolean = false;
  
  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      767: {
        items: 2
      },
      1024: {
        items: 3
      }
    },
    nav: true
  }
    slides = [
      {id: 1, img: "https://dummyimage.com/350x150/423b42/fff"},
      {id: 2, img: "https://dummyimage.com/350x150/2a2b7a/fff"},
      {id: 3, img: "https://dummyimage.com/350x150/1a2b7a/fff"},
      {id: 4, img: "https://dummyimage.com/350x150/7a2b7a/fff"},
      {id: 5, img: "https://dummyimage.com/350x150/9a2b7a/fff"},
      {id: 6, img: "https://dummyimage.com/350x150/5a2b7a/fff"},
      {id: 6, img: "https://dummyimage.com/350x150/4a2b7a/fff"}
    ];
  title = 'ALLINONE';
  public  loginSuccess:boolean=false;
  cl=new Array();
  UniqueId:number=0;
  navbarCollapsed:boolean=true;
  SelecteDeliv:number=0;
  totalcartCount:number=0;
  shakeCart:string="";
  weburl:string="";
  CopyrightFrom:string="";
  ShopName:string="";
  Version:string="";
  constructor(private cookieser:CookieService,private dynamicScriptLoader: DynamicScriptLoaderService,private elementRef:ElementRef,private route:Router,private activatedRoute: ActivatedRoute,
    private communicationService: CommunicationServiceService,private se:SercallService,private datePipe: DatePipe
    ,private meta: MetaService){
     
    this.loadScript('assets/js/jquery-2.2.4.min.js');
 
this.loadScript('assets/bootstrap/js/bootstrap.min.js'); 
this.loadScript('assets/owlcarousel/js/owl.carousel.min.js'); 
this.loadScript('assets/js/magnific-popup.min.js'); 
this.loadScript('assets/js/waypoints.min.js'); 
this.loadScript('assets/js/parallax.js');  
this.loadScript('assets/js/jquery.countdown.min.js'); 
this.loadScript('assets/js/jquery.countTo.js'); 
this.loadScript('assets/js/imagesloaded.pkgd.min.js'); 
this.loadScript('assets/js/isotope.min.js'); 
this.loadScript('assets/js/jquery.appear.js'); 
this.loadScript('assets/js/jquery.dd.min.js'); 
this.loadScript('assets/js/slick.min.js'); 
this.loadScript('assets/js/datepicker.min.js'); 
this.loadScript('assets/js/mdtimepicker.min.js'); 
  }
  ngAfterViewInit()
  {
     
  }
  //  
  ngOnInit() { 
   this.initializeParameters();
    this.GetVoucher();
    if (localStorage.getItem("DelType") != null)
  this.SelecteDeliv = Number.parseInt(localStorage.getItem("DelType"));
else {
  this.DeliverySel();
}
    // Just call your load scripts function with scripts you want to load
    var checkcookie=localStorage.getItem('AuthToken');
    if(checkcookie==null)
    this.loginSuccess=false;
    else
    this.loginSuccess=true;
    this.LoadPaymentGatewayDataContent();
    this.LoadShopTimming();
    this.LoadContactUs();
    this.LoadLogoAndContent();
    this.LoadSocialMedia();
    this.loadCart();
    var arr = JSON.parse(localStorage.getItem('PaymentGatewayData'));
      this.Gateway_Type=arr[0].Gateway_Type;
      this.Public_Key=arr[0].Public_Key;
      this.Client_Id=arr[0].Client_Id;
      this.loadPaypal()
    
  }
  loadPaypal()
{
    const script = window.document.createElement("script");
    script.src='https://www.paypal.com/sdk/js?client-id=' + this.Client_Id+'&currency=GBP';
    // console.log(script);
    this.loadScript(script.src.toString());
}
initializeParameters()
{
  //theme-color-defaults - blue:#0c155a , red:#ff324d , green:#1ea048
  document.documentElement.style.setProperty('--blue', '#0c155a'); //theme-color
  this.Version="V1";
  localStorage.setItem("Version",this.Version);
  // localStorage.removeItem("PRIVACY_TERMS_DELIVERY_POLICY");
  this.CopyrightFrom="2021-"+new Date().getFullYear(); //copyright year
  this.ShopName="All In One"; //shopname
  this.weburl="https://allinonepolmont.co.uk/"; //website url
  localStorage.setItem("weburl",this.weburl);
 /* localStorage.setItem("apiurl","http://localhost:11279/"); //api url */
   localStorage.setItem("apiurl","https://www.allinonepolmont.co.uk/api/"); //api url 
}
GetVoucher()
{
  var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_Home_Page_Details"};
  this.se.postdata<LoginModel.CommonRreponse<HomeBannerResp>>('v1/api/get-set-information-more',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
        {
          if(data.ResponseObject["Voucher_Code"]!="")
          {
            localStorage.setItem("DefaultCoupen",data.ResponseObject["Voucher_Code"][0]["Voucher_Code"]);
            localStorage.setItem("PRIVACY_TERMS_DELIVERY_POLICY",JSON.stringify(data.ResponseObject["PRIVACY_TERMS_DELIVERY_POLICY"]));
          }
          else{
            localStorage.removeItem("DefaultCoupen");
            localStorage.setItem("PRIVACY_TERMS_DELIVERY_POLICY",JSON.stringify(data.ResponseObject["PRIVACY_TERMS_DELIVERY_POLICY"]));
          }
        }
      }
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {

      }
    }
  });
}
paymentcontent:PaymentGatewayContent[];
LoadPaymentGatewayDataContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<PaymentGatewayContentResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.paymentcontent=data.ResponseObject.PaymentGatewayData;
        localStorage.setItem("PaymentGatewayData",JSON.stringify(this.paymentcontent));
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadPaymentGatewayDataContent();

    }
  }
});
}
  addToHomeScreen() {
    if(this.deferredPrompt==null)
    {
      alert("App Already Installed");
      return;
    }
    else
    {
 // hide our user interface that shows our A2HS button
 this.showButton = false;
 // Show the prompt
 this.deferredPrompt.prompt();
 // Wait for the user to respond to the prompt
 this.deferredPrompt.userChoice
   .then((choiceResult) => {
     if (choiceResult.outcome === 'accepted') {
       console.log('User accepted the A2HS prompt');
     } else {
       console.log('User dismissed the A2HS prompt');
     }
     this.deferredPrompt = null;
   });
    }
   
  }
  
  DeliverySel() {
    localStorage.setItem("DelType", this.SelecteDeliv.toString());
    this.loadCart();
  }
   ShakeCart()
   {
     this.shakeCart="";
     setTimeout(() => {
      this.shakeCart="shake";
     }, 200);
   }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  } 
  Logout()
  { 
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("_cart");
    this.route.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
  subtotal=0; 
  cartempty = true
  loadCart() {
    this.totalcartCount=0;
    this.subtotal=0
    this.cl = this.getCart();
    if (this.cl.length > 0)
    {
      this.cartempty = false
      for (var i = 0; i < this.cl.length; i++) {
         if(this.SelecteDeliv==0)
         {
           if(this.cl[i].selectedSize!=null)
           this.cl[i].ItemTotal=(this.cl[i].selectedSize.Dilivery_Price*this.cl[i].Qty);
        else
        this.cl[i].ItemTotal=(this.cl[i].Dilivery_Price*this.cl[i].Qty);

         }
        else
        {
          if(this.cl[i].selectedSize!=null)
          this.cl[i].ItemTotal=(this.cl[i].selectedSize.Takeaway_Collection_Bar_Price*this.cl[i].Qty);
        else
        this.cl[i].ItemTotal=(this.cl[i].Takeaway_Collection_Bar_Price*this.cl[i].Qty);
        } 
        if(this.cl[i].Parent_Id!=undefined)
        {
          this.cl[i].CartName='-'+this.cl[i].Name;
          if(this.cl[i].selectedSize!=null)
          this.cl[i].CartName='-'+this.cl[i].Name+'-'+this.cl[i].selectedSize.Name;
        }
        this.subtotal+=this.cl[i].ItemTotal;
        this.totalcartCount+=this.cl[i].Qty;
      }
      this.communicationService.emitChange()
      this.ShakeCart();
    }
    else
    {
      this.cartempty=true
      this.UniqueId=0;
    }
    
    
  }
  getCart()
  {
    var cart= localStorage.getItem('_cart')
    if(cart!=null&&cart!="")
     return JSON.parse(cart)
     else
     return []; 

  }
  decCartItems(obj:MenuModel.Item)
  {
     
    this.decCart(obj);

  }
  checkZeroPrice(obj:MenuModel.Item)
  {
    if(obj.DineIn_Price==0&&obj.Dilivery_Price==0&&obj.Takeaway_Collection_Bar_Price==0)
    {
      return false;
    }
    if(obj.SizeExists&&obj.selectedSize.DineIn_Price==0&&obj.selectedSize.Dilivery_Price==0&&obj.selectedSize.Takeaway_Collection_Bar_Price==0)
    {
      return false;
    }
    return true;
  }
  incCartItems(obj:MenuModel.Item)
  {
  
    if(this.checkZeroPrice(obj))
    this.incCart(obj);

  }
  decCart(obj:MenuModel.Item) {
    
    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].ItemUniqueid == obj.ItemUniqueid) {
        cart[i].Qty = cart[i].Qty - 1
        if(!cart[i].SizeExists)
        {
          cart[i].ItemTotal=cart[i].Qty*obj.Dilivery_Price;
         
        }
        else
        { 
          cart[i].ItemTotal=cart[i].Qty*obj.selectedSize.Dilivery_Price; 
        }  
         

        if(cart[i].Qty<=0)
        {
          cart.splice(i,1);
        }
        
        added = true;
        break;
      }
      
    }
     
    localStorage.setItem('_cart',JSON.stringify(cart))
    this.loadCart();
    if(obj.ItemUniqueid!=undefined)
    {
    let childitems= cart.filter((ele:MenuModel.Item)=>ele.Parent_Id==obj.ItemUniqueid);
    childitems.forEach(element => {
     this.decCart(element);
    });
  }
    return false
  }
  incCart(obj:MenuModel.Item) {
    
    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].ItemUniqueid == obj.ItemUniqueid) {
        cart[i].Qty = cart[i].Qty + 1
        if(!cart[i].SizeExists)
        {
          cart[i].ItemTotal=cart[i].Qty*obj.Dilivery_Price;
          
         
        }
        else
        { 
          cart[i].ItemTotal=cart[i].Qty*obj.selectedSize.Dilivery_Price; 
        }  
           
        added = true;
        break;
      }

    }
     
    localStorage.setItem('_cart',JSON.stringify(cart))
    this.loadCart()
    if(obj.ItemUniqueid!=undefined)
    {
    let childitems= cart.filter((ele:MenuModel.Item)=>ele.Parent_Id==obj.ItemUniqueid);
         childitems.forEach(element => {
          this.incCart(element);
         });
        }
    return false
  } 

hideoverlay()
{
   document.getElementById('header-overlay').click(); 
   
}   
logoandcontent:LogoAndContent[];
LoadLogoAndContent()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<LogoAndContentResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.logoandcontent=data.ResponseObject.LogoAndContents;
        localStorage.setItem("LogoAndContent",JSON.stringify(this.logoandcontent));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadLogoAndContent();

    }
  }
});
}

GetHourMin1(){
  var hour= this.selectedHour;
  var min= this.selectedMin;
  this.currentTime1=hour +":" +min;
 }
 checkoutCart() {

  if (this.cl != undefined && this.cl.length > 0) {
    if (!this.shopStatus.Shop_Status && this.shopStatus.PreOrder)
      localStorage.setItem("_cartMeta", JSON.stringify({ "shopPreorder": this.shopStatus.PreOrder, "OrderTime": this.selectedHour + ":" + this.selectedMin }));
    else if(this.shopStatus.Shop_Status && this.shopStatus.PreOrder)
    localStorage.setItem("_cartMeta", JSON.stringify({ "ScheduleOrder": this.shopStatus.PreOrder, "OrderTime": this.selectedHour + ":" + this.selectedMin }));
      else
      localStorage.removeItem("_cartMeta");
      this.hideoverlay();
    //    this.route.navigate(['/checkout']).then(() => {
    //   window.location.reload();
    // });
    this.route.navigate(['/checkout']);
  }
}
shoptimming:ShopTiming[];
ShopTimingsforpreorder:ShopTiming[];
shopStatus:ShopStatus;
LoadShopTimming()
{
  var date = new Date();
   
  var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":this.datePipe.transform(date,"yyyy-MM-dd HH:mm"),"IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Shop_Info"};
  this.se.postdata<LoginModel.CommonRreponse<ShopTimingResp>>('v1/api/get-set-information-more',payload).then(data=>{
      if(data.ResponseCode==0)
      {
        if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
        {
          this.ShopTimingsforpreorder=data.ResponseObject.ShopTimingsforpreorders;
          this.shoptimming=data.ResponseObject.ShopTimings;
          this.shopStatus=data.ResponseObject.ShopStatus[0];
          localStorage.setItem("ShopStatus",JSON.stringify(this.shopStatus));
          localStorage.setItem("ShopTimming",JSON.stringify(this.shoptimming));
          localStorage.setItem("ShopTimingsforpreorder",JSON.stringify(this.ShopTimingsforpreorder));
          this.LoadShopOpenClose();
        }
      }
  }).catch(e => {

    if (e.status == 401) {
      if (this.se.CheckRefreshToken()) {
        this.LoadShopTimming();

      }
    }
  });

}
LoadShopOpenClose()
{
  this.shopStatus = JSON.parse(localStorage.getItem("ShopStatus"));
var dat = new Date();
if (this.shopStatus != undefined) {

  if (this.shopStatus.Shop_Status) {
  //
  if (this.shopStatus.PreOrder) {
    this.currentTime = this.datePipe.transform(dat, "hh:mm");
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


    var shoptimming = JSON.parse(localStorage.getItem("ShopTimingsforpreorder"));
    var shoptime = shoptimming.filter(function (t, u) {
      return t.Day_Name == days[dat.getDay()];
    })
    if (shoptime != undefined && shoptime.length > 0) {
      var regex = new RegExp(':', 'g');
      var from = shoptime[0].Opening_Time;
      var to = shoptime[0].Closing_Time;
      this.currentTime1=from;
      var CTime=this.datePipe.transform(dat, "HH:mm:ss");

      console.log(CTime);
      if(parseInt(CTime.replace(regex, ''), 10) < parseInt(from.replace(regex, ''), 10))
      {
          this.show = true;  
          this.labelText="Shop is closed now but you can preorder if you want."; 
          this.type1="Schedule1";
          this.isDisabledState1=true;
          var fromhour = Number.parseInt(from.replace(':', '').substring(0, 2))
          var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
          // tohour=Date.parse(tohour.toString());
          this.selectedHour = fromhour;
          // this.selectedMin=="05";
          for (let index = fromhour; index <= tohour-1; index++) {
            this.hourlist.push(index)
          }
          this.hourlist.pop();
          this.minutelist.pop();
          for(let i = 0; i<60; i++)
                  {
                    this.minutelist.push(i);
                  }

                  for(let i = 0; i<60; i++)
                  {
                    this.minutelist.push(i);
                  }
                  this.minutelist.pop();

      }
      else
      {
        this.type1="ASAP1";
        this.isDisabledState1=false;
        var fromhour = Number.parseInt(CTime.replace(':', '').substring(0, 2))
        var tohour = Number.parseInt(to.replace(':', '').substring(0, 2))
        this.selectedHour = fromhour;
        // if(this.selectedMin=="05")
        //       {
        //         this.selectedMin=this.datePipe.transform(dat, "mm");
        //       }
        for (let index = fromhour; index <= tohour; index++) {
          this.hourlist.push(index)
        }
        for(let i = dat.getMinutes(); i<60; i++)
              {
                this.minutelist.push(i);
              }
              this.selectedMin = dat.getMinutes().toString();
              this.minutelist.pop();
        this.hourlist.pop();
      }
      console.log(from.replace(':', '').substring(0, 4), to.replace(':', '').substring(0, 4));

    }
  }
    else {
      this.shopEnabled = false;
    }

  }
  else 
  {
    this.show = true;  
    this.labelText="Shop is now closed you cannot order."; 
    this.isDisabledAddButton=false;
    // Menu Button Disable, Message - Shop Is Closed 
  
  }
}
}
contactus:ContactUs[];
LoadContactUs()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Contact_Details"};
this.se.postdata<LoginModel.CommonRreponse<ContactUsResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.contactus=data.ResponseObject.ContactDetails;
        localStorage.setItem("AboutContent",JSON.stringify(this.contactus));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadContactUs();

    }
  }
});
}


socialmedia:SocialMedia[];
LoadSocialMedia()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<SocialMediaResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.socialmedia=data.ResponseObject.SocialMedias;
        localStorage.setItem("SocialMedia",JSON.stringify(this.socialmedia));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadSocialMedia();

    }
  }
});
}

// window.addEventListener('beforeinstallprompt', (e) => {
//     deferredPrompt = e;
// });
// const installApp = document.getElementById('installApp');

// installApp.addEventListener('click', async () => {
//     if (deferredPrompt !== null) {
//         deferredPrompt.prompt();
//         const { outcome } = await deferredPrompt.userChoice;
//         if (outcome === 'accepted') {
//             deferredPrompt = null;
//         }
//     }
// });
  
}
