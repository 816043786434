import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import { categorymodel } from '../categorymodel';
import { SercallService} from '../sercall.service';
import { StatusModel } from '../StatusModel';

@Component({
  selector: 'app-catheader',
  templateUrl: './catheader.component.html',
  styleUrls: ['./catheader.component.css']
})
export class CatheaderComponent implements OnInit {
  l=new Array<categorymodel>();
  constructor(private se:SercallService,private route:Router) { }

  ngOnInit() {
    this.se.getdata<StatusModel>('http://localhost:81/api/Values/getcats').then(data=>{
      console.log(data)
      this.l=data.Data
    //   const newLocal = 'http://localhost:4200/';
    // if(window.location.href==newLocal)
    //   this.route.navigate(['/item',data.Data[0].Category_Name,data.Data[0].Id]);
      
            })
  }

}
