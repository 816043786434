// import { getOrSetAsInMap } from '@angular/animations/browser/src/render/shared';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { CommunicationServiceService } from '../communication-service.service';
import { SercallService } from '../sercall.service';
import { CheckoutService } from '../services/checkout.service';
import { DeliveryCharge, OrderDetail, OrderPayload, OrderResp, PaymentGatewayContent, PaymentGatewayContentResp, StatusModel, VoucherInfo, VoucherResp } from '../StatusModel';
declare var paypal;
let elements;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('paypal') paypalElement: ElementRef; 
  private comsubsrciption:Subscription; 
  constructor(@Inject(AppComponent) private AppCompoParent: AppComponent, private route: Router,private checkout: CheckoutService, private se: SercallService,private communicationService: CommunicationServiceService,private datePipe: DatePipe){
    this.comsubsrciption= communicationService.changeEmitted$.subscribe(data => {
      // here fetch data from the session storage 
      this.LoadCart();
    })    
    this.removeSripts();
        this.loadScript('assets/js/scripts.js');
   }
   ngOnDestroy() {
    this.comsubsrciption.unsubscribe();
  }
   public removeSripts()
  {
      var arr=document.getElementsByTagName('script');
      for (let index = 0; index < arr.length; index++) {
        if(arr[index].src.includes('scripts.js'))
        {
          arr[index].remove();
          break;
        }
      }
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
     
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  } 
  cl = new Array();
  subtotal: number = 0;
  showLoader: boolean = false;
  ProfileModel: LoginModel.LoginRespponse;
  Cust_Name: string;
  E_Mail: string;
  Mobile: string;
  PostCode: string;
  deliveryCharge: DeliveryCharge;
  voucher:VoucherInfo;
  delCharge:number=0;
  discount:number=0;
  gTotal:number=0;
  errorMsg:string="";
  couponCode:string="";
  errorMsgCoupon:string="";
  Add1:string="";
  Add0:string="";
  Ordertype:string="COD";
  Transid:string="";
  TransStatus:number=0;
  paybtntext:string="PAY ON COLLECTION";
 paymentEnable:boolean=true;
 paymentHandler:any = null;
 success: boolean = false;
 Gateway_Type:string ="";
 Public_Key:string ="";
 Client_Id:string ="";
 StripeEnable:boolean=false;
 Disableclicked:boolean=false;
 
 failure:boolean = false

  ngOnInit() {
    this.Cust_Name = '';
    if (!this.AppCompoParent.loginSuccess) {
      this.route.navigate(['/login'])
    }
    this.paybtntext= localStorage.getItem("DelType")=="0"?"PAY ON DELIVERY":"PAY ON COLLECTION";
    this.LoadProfile();
    var arr = JSON.parse(localStorage.getItem('PaymentGatewayData'));
      this.Gateway_Type=arr[0].Gateway_Type;
      this.Public_Key=arr[0].Public_Key;
      this.Client_Id=arr[0].Client_Id;
  if(this.Gateway_Type==="Stripe")
  {
    this.StripeEnable=true;
    this.LoadPaymentButtons1();
  }
  else{
    // this.loadPaypal();
    this.StripeEnable=false;
    this.LoadPaymentButtons();
  }

  }
  initializePayment(amount: number) {
    
    if(localStorage.getItem("DelType")=="0")
    {
    if(this.Add0=="")
    {
      alert("Please Enter House No.");
      return false;
    }
    if(this.Add1=="")
    {
      alert("Please Enter Full Address");
      return false;
    }
    }
    const self = this; 
    this.Disableclicked = true;
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.Public_Key,
      locale: 'auto',
      token: function (stripeToken: any) {
        console.log(stripeToken);
        stripeToken.amount=amount;
        paymentstripe(stripeToken);
      },
    });

    const paymentstripe = (stripeToken: any) => {
     
      try {
         this.checkout.makePayment(stripeToken).subscribe((data: any) => {
        console.log(data);
        var aaa=data.Data.stripeCharge.status;
        console.log(aaa);
        if (data.Data.stripeCharge.status === "succeeded") {
          this.success = true
           self.Ordertype="online"; 
                        // $('[id*=hdnStatus]').val(details.status);
                        // $('[id*=hdnId]').val(details.id);
                        self.Transid=stripeToken.id;
                        // $('[id*=hdnTrans_Type]').val('Online');
                        // $('[id*=btnRegister]').click();
                        self.PlaceOrder();
        }
        else {
          alert(data.Data.stripeCharge.failure_message);
          this.failure = true
        }
      });
       
    } catch (error) {
      return false;
    }
    };

    paymentHandler.open({
      name: 'All in One',
      description: 'All in One All in One, Take away in Polmont, Best take away in Polmont.',
      amount: amount * 100,
    });
  }
  LoadPaymentButtons1()
  {  
    if(!this.paymentEnable)
    return;
    try{
      if(!window.document.getElementById('stripe-script')) {
        const script = window.document.createElement("script");
        script.id = "stripe-script";
        script.type = "text/javascript";
        script.src = "https://checkout.stripe.com/checkout.js";
        script.onload = () => {
          this.paymentHandler = (<any>window).StripeCheckout.configure({
            key: this.Public_Key,
            locale: 'auto',
            token: function (stripeToken: any) {
              console.log(stripeToken);
            },
          });
        }
        window.document.body.appendChild(script);
      }
}catch(e)
{

}
  }

// loadPaypal()
// {
//   if(!window.document.getElementById('paypal-script')) {
//     const script = window.document.createElement("script");
//     script.id = "paypal-script";
//     script.type = "text/javascript";
//     script.src='https://www.paypal.com/sdk/js?client-id=' + this.Client_Id + ';currency=GBP" data-sdk-integration-source="button-factory" data-uid-auto="'+this.Public_Key;
//     console.log(script);
//     window.document.body.appendChild(script);
//   }
// }
  LoadPaymentButtons()
  {  
    if(!this.paymentEnable)
    return;
    try{
    const self = this; 
    
    this.paypalElement.nativeElement.innerHTML='';
    var payload={ "amount": { "currency_code": "GBP", "value": this.gTotal.toFixed(2).toString() } };
     
    paypal.Buttons({
      style:
      {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',

      },

      createOrder: function (data, actions) {
          return actions.order.create({
              purchase_units: [payload]
          });
      }, onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            self.Ordertype="online"; 
              // $('[id*=hdnStatus]').val(details.status);
              // $('[id*=hdnId]').val(details.id);
              self.Transid=details.id;
              // $('[id*=hdnTrans_Type]').val('Online');
              // $('[id*=btnRegister]').click();
              self.PlaceOrder()
          });
      }
      ,  onCancel: function (data)  {
        // Show a cancel page, or return to cart 
       },

      onError: function (err) { 
      }
  }).render('#paypal-button-container');
}catch(e)
{

}
  }
  LoadCart() {


    this.subtotal = 0
    this.cl = this.getCart();
    if(this.cl.length==0)
    {
      this.route.navigate(['/menu']);
    }
    this.cl.map((ele) => {

      this.subtotal += parseFloat(ele.ItemTotal)
      if(ele.Parent_Id!=undefined)
          {
            ele.CartName='-'+ele.Name;
          }
    }) 
    this.gTotal=this.subtotal+this.delCharge-this.discount;
    if(this.Gateway_Type==="Stripe")
    {
      this.StripeEnable=true;
      this.LoadPaymentButtons1();
    }
    else{
      // this.loadPaypal();
      this.StripeEnable=false;
      this.LoadPaymentButtons();
    }
  }
  getCart() {
    var cart = localStorage.getItem('_cart')
    if (cart != null && cart != "")
      return JSON.parse(cart)
    else
      return [];


  }
  LoadProfile() {
    this.showLoader = true;
    this.se.getdata<LoginModel.LoginResponseObject>('v1/api/user/get-profile').then(data => {
      this.LoadCart();
      this.ApplyCoupon();
      this.ProfileModel = data.ResponseObject;
      this.Cust_Name = this.ProfileModel.Cust_Name;
      this.E_Mail = this.ProfileModel.E_Mail;
      this.Mobile = this.ProfileModel.Mobile;
      this.PostCode = this.ProfileModel.PostCode;
      this.Add0=this.ProfileModel.Add0;
      this.Add1=this.ProfileModel.Add1;
      if (this.PostCode != ""&&localStorage.getItem("DelType")=="0") {
        var payload = {
          "StrFeild1": this.PostCode,
          "StrFeild2": "",
          "StrFeild3": "",
          "StrFeild4": "",
          "IntFeild1": 0,
          "IntFeild2": 0,
          "DecimalFeild1": 0,
          "DecimalFeild2": 0,
          "CMDTYPE": "Delivery_Price_On_Postcode"
        };

        this.se.postdata<LoginModel.CommonRreponse<DeliveryCharge>>('v1/api/get-set-information-more', payload)
          .then(data => {
            this.paymentEnable=false;
          
            this.paymentEnable=true;
            
            if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
            {
              
              this.delCharge=data.ResponseObject.Delivery_Price_Info[0].Dilivery_Charges;
              if(data.ResponseObject.Minimum_Delivery_Amount[0].Minimum_Delivery_Amount>0)
              {
                if(localStorage.getItem("DelType")=="0"&&data.ResponseObject.Minimum_Delivery_Amount[0].Minimum_Delivery_Amount>this.gTotal)
                {
                  this.errorMsg="Minimum amount needed in the cart is £"+data.ResponseObject.Minimum_Delivery_Amount[0].Minimum_Delivery_Amount+
                  ". Please add more item in cart";
                  this.paymentEnable=false;
                }
              }
            }
            else{ 
              this.delCharge=0;
              this.errorMsg=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
              if(localStorage.getItem("DelType")=="0"&&data.ResponseObject.Minimum_Delivery_Amount[0].Minimum_Delivery_Amount>this.gTotal)
                {
                  this.errorMsg="Minimum amount needed in the cart is £"+data.ResponseObject.Minimum_Delivery_Amount[0].Minimum_Delivery_Amount+
                  ". Please add more item in cart";
                  
                }
                this.paymentEnable=false;
            }
            this.LoadCart();
          }).catch(e => {

            if (e.status == 401) {
              if (this.se.CheckRefreshToken()) {
                this.LoadProfile();

              }
            }
          });
      }
      this.showLoader = false;
    }).catch(e => {

      if (e.status == 401) {
        if (this.se.CheckRefreshToken()) {
          this.LoadProfile();

        }
      }
    })
  }
  ApplyCoupon()
  { 
    this.errorMsgCoupon="";
    if(localStorage.getItem("DefaultCoupen")!=null)
    {
      if(this.couponCode=="")
      {
        this.couponCode=localStorage.getItem("DefaultCoupen");
      }
    }
   var date = new Date();
   if (this.couponCode.trim() != "") {
      var payload = {
        "StrFeild1": this.couponCode.trim(),
        "StrFeild2": "",
        "StrFeild3": "",
        "StrFeild4": this.datePipe.transform(date,"yyyy-MM-dd HH:mm"),
        "IntFeild1": 0,
        "IntFeild2": 0,
        "DecimalFeild1": this.subtotal,
        "DecimalFeild2": 0,
        "CMDTYPE": "Apply_Voucher"
      };
      
      this.showLoader=true;
      this.se.postdata<LoginModel.CommonRreponse<VoucherResp>>('v1/api/get-set-information-more', payload)
        .then(data => {
     
          if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
          {
            this.voucher=data.ResponseObject.Voucher_Info[0];
             this.discount=data.ResponseObject.Voucher_Info[0].Discount;
          }
          else{
            this.voucher=null;
            this.discount=0;
            this.errorMsgCoupon=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
          }
          this.LoadCart();
          this.showLoader=false;
        }).catch(e => {

          if (e.status == 401) {
            if (this.se.CheckRefreshToken()) {
              this.ApplyCoupon();

            }
          }
        });
    }
    // else
    // {this.discount=0;
    //   this.couponCode="";
    //   this.voucher=null;
    //   this.LoadCart();
    //   document.getElementById('txtCoupon').focus();
    // }
  }
  clicked = false;
  PlaceOrder()
  {
    if(localStorage.getItem("DelType")=="0")
    {
    if(this.Add0=="")
    {
      alert("Please Enter House No.");
      return false;
    }
    if(this.Add1=="")
    {
      alert("Please Enter Full Address");
      return false;
    }
    }
    this.onProfileSubmit();
    this.Disableclicked = true;
    this.showLoader=true;
    let orderpayload=new OrderPayload();
    orderpayload.order.Order_Id="";
    orderpayload.order.Cust_id=0;
    orderpayload.order.Paid_Amount=0;
    orderpayload.order.Pay_Card=0;
    orderpayload.order.Delivery_Note=this.Transid;
    var getPreorderTime=JSON.parse(localStorage.getItem("_cartMeta"));
    if(getPreorderTime!=undefined)
    {
      orderpayload.order.Delivery_time=this.datePipe.transform(new Date(),"yyyy-MM-dd "+getPreorderTime.OrderTime);;
    }
    else
    {
      orderpayload.order.Delivery_time=this.datePipe.transform(new Date(),"yyyy-MM-dd HH:mm");;
    }
    
   
    orderpayload.order.Net_Amount=this.gTotal;
    orderpayload.order.Sub_Total=this.subtotal;
    orderpayload.order.Delivery_Charge=this.delCharge;
    if(this.Ordertype=="online")
        {
          orderpayload.order.Paid_Amount= this.gTotal;
        }
    if(this.voucher!=null)
    {
    orderpayload.order.Voucher_Id=this.voucher.Voucher_Id;
    orderpayload.order.Voucher_Per=this.voucher.Voucher_Per;
    orderpayload.order.Discount=this.discount;
    }
    orderpayload.order.Order_from="Website";
    var deltype=localStorage.getItem("DelType")=="0"?"Delivery":"Collection";
    orderpayload.order.Order_Type=deltype;
  let i=0;

    this.cl.map((data)=>{
      i++;
        let Od=new OrderDetail();
        Od.Item_Name=data.CartName
        Od.IsPriceEdited=false;
        Od.ItemType=data.ItemType;
        Od.ParentId=data.Parent_Id;
        Od.Price=data.ItemTotal;
        Od.Price_Id=data.Price_Id;
        Od.PrintStatus=0;
        Od.PrinterId=data.PrinterId;
        Od.Quantity=data.Qty;
        Od.Takeaway_Collection_Bar_Price=data.Takeaway_Collection_Bar_Price;
        Od.ThirdParty_Price=data.ThirdParty_Price;
        Od.ViewOrder=i;
        Od.CourseName=data.CourseName;
        Od.CourseOrder=data.CourseOrder;
        Od.Dilivery_Price=data.Dilivery_Price;
        Od.DineIn_Price=data.DineIn_Price;
        Od.Id=i++; 
        orderpayload.OD.push(Od);
    }) 
    this.se.postdata<LoginModel.CommonRreponse<OrderResp>>('v1/api/place-order',orderpayload).then(data=>{
        
      if(data.ResponseCode==0)
      {
        // if(this.Ordertype=="online")
        // {
        //   this.TransStatus=1;
        //   this.UpdateOrder(data);

        // }
        // else
        // {
        //   this.TransStatus=3;
        //   this.UpdateOrder(data);
        // }
        localStorage.setItem("DelType", "0");
              this.route.navigate(['/OrderStatus']);
      }

    }).catch(e => {

          if (e.status == 401) {
            if (this.se.CheckRefreshToken()) {
              this.PlaceOrder();
            }
          }
        });
    
  }
UpdateOrder(data:LoginModel.CommonRreponse<OrderResp>)
{
  var payload=
          {
            "StrFeild1": data.ResponseObject.OrderDetails[0].Order_Id,
            "StrFeild2":this.Transid,
            "StrFeild3":"",
            "StrFeild4":"",
            "IntFeild1":this.TransStatus,
            "IntFeild2":0,
            "DecimalFeild1":0,
            "DecimalFeild2":0,
            "CMDTYPE":"Update_Web_Order_Trans_Status"
          }
          this.se.postdata<LoginModel.CommonRreponse<VoucherResp>>('v1/api/get-set-information-more', payload)
          .then(data=>{
            if(data.ResponseCode==0&&data.ResponseObject.RequestStatus[0].ERROR_NO==0)
            {
              localStorage.setItem("DelType", "0");
              this.route.navigate(['/OrderStatus']);
            }

          }).catch(e => {

            if (e.status == 401) {
              if (this.se.CheckRefreshToken()) {
                this.UpdateOrder(data);
  
              }
            }
          });
}
onProfileSubmit() {
  this.showLoader=true;
  // this.disableBtn=true;
  // this.showLoader=true;
  //this.errorMsg="";
  
  this.ProfileModel.Cust_Name=this.Cust_Name; 
  this.ProfileModel.E_Mail=this.E_Mail; 
  this.ProfileModel.Mobile=this.Mobile; 
  this.ProfileModel.Add0=this.Add0; 
  this.ProfileModel.Add1=this.Add1; 
  this.ProfileModel.PostCode=this.PostCode; 
    
  this.se.postdata<LoginModel.LoginResponseObject>('v1/api/user/update-profile',this.ProfileModel).then(data=>{
    if(data.ResponseCode==0)
    {
         
    }
    else
    {
       
      //show error msg from server
    }
    this.showLoader=false; 
    // this.disableBtn=false;
    // this.showLoader=false;
  });
  
}
}
