import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-policy',
  templateUrl: './delivery-policy.component.html',
  styleUrls: ['./delivery-policy.component.css']
})
export class DeliveryPolicyComponent implements OnInit {
  AllData = new Array();
  constructor() {
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
}

ngOnInit() {
  this.AllData=JSON.parse(localStorage.getItem('PRIVACY_TERMS_DELIVERY_POLICY')).filter(PRIVACY_TERMS_DELIVERY_POLICY => PRIVACY_TERMS_DELIVERY_POLICY.Page_Id === 3);
}
decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 

}
