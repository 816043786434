import { Component, OnInit } from '@angular/core';
import { MetaService } from '../meta.service';
import { SercallService } from '../sercall.service';
import { ImageGallery, ImageGalleryResp } from '../StatusModel';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  weburl:string="";
  constructor(private meta:MetaService,private se:SercallService) {
    this.meta.updateTitle();
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
}

ngOnInit() {
   this.weburl=localStorage.getItem("weburl");
  
this.Loadimagegallery();
}
imagegallery:ImageGallery[];
Loadimagegallery()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Website_About_Page_Details"};
this.se.postdata<LoginModel.CommonRreponse<ImageGalleryResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.imagegallery=data.ResponseObject.ImageGallerys;
        localStorage.setItem("ImageGallery",JSON.stringify(this.imagegallery));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.Loadimagegallery();

    }
  }
});
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 

}
