import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SercallService } from '../sercall.service';
import { ForgotResponse } from '../StatusModel';
var self;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private se:SercallService,private route: Router) {
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
    self=this;
}
txtEmail:string="";
txtOtp:string="";
errorMsg:string="";
showdiv:number=1;
txtPassword:string="";
txtConfPassword:string="";
SuccessMsg:string="";
showLoader:boolean=false;
ngOnInit() {
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 
SubmitForgot()
{
   
  this.errorMsg="";
  if(this.txtEmail=="")
  {
    document.getElementById('txtEmailId').focus();
    return;
  }
  this.showLoader=true;
  var payload={E_Mail:this.txtEmail.trim()}
  this.se.postdata<LoginModel.CommonRreponse<ForgotResponse>>('v1/api/user/forgot-password',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.showdiv=2;
      }
      else
      {
        this.errorMsg=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
      }
    }
    else
    {
        this.errorMsg=data.ResponseMessage
    }
    this.showLoader=false;
  })
}
SubmitOtp()
{
  
  this.errorMsg="";
  if(this.txtOtp=="")
  {
    document.getElementById('txtOtp').focus();
    return;
  }
  this.showLoader=true;
  var payload={E_Mail:this.txtEmail.trim(),OTP :this.txtOtp.trim()}
  this.se.postdata<LoginModel.CommonRreponse<ForgotResponse>>('v1/api/user/otp-validate',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.showdiv=3;
      }
      else
      {
        this.errorMsg=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
      }
    }
    else
    {
        this.errorMsg=data.ResponseMessage
    }
    this.showLoader=false;
  })
}

SubmitPass()
{
   
  this.errorMsg="";
  if(this.txtPassword.trim()=="")
  {
    document.getElementById('txtPassword').focus();
    return;
  }
  if(this.txtConfPassword.trim()=="")
  {
    document.getElementById('txtConfPassword').focus();
    return;
  }
  if(this.txtPassword.trim()!=this.txtConfPassword.trim())
  {
    this.errorMsg="Password not matched";
    return;
  }
  this.showLoader=true;
  var payload={E_Mail:this.txtEmail.trim(),OTP :this.txtOtp.trim(),New_Password:this.txtPassword.trim()}
  this.se.postdata<LoginModel.CommonRreponse<ForgotResponse>>('v1/api/user/password-update',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
       
        this.SuccessMsg="Password Updated";
        
          this.route.navigate(['/login']);
         
        
        
        
      }
      else
      {
        this.errorMsg=data.ResponseObject.RequestStatus[0].SQL_ERROR_MESSAGE;
      }
    }
    else
    {
        this.errorMsg=data.ResponseMessage
    }
    this.showLoader=false;
  })
}

}
