import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService {

  constructor() { }
  
  private emitChangeSource = new Subject<any>();
  private emitChangeSource1 = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  changeEmitted1$ = this.emitChangeSource.asObservable();

  emitChange() {
      this.emitChangeSource.next();
  }
  emitChange1() {
    this.emitChangeSource1.next();
}
}
