export class StatusModel{

    public status:number
    public status_Msg:string
    public Data:any
    
}
export class UpdatePassword {
    Old_Password:string;
    New_Password: string; 
    Confirm_Password: string; 
}
export class DeleteAccount {
    Old_Password:string;
    Confirm_Password: string; 
}
export interface RequestStatus {
    ERROR_NO: number;
    SQL_ERROR_NO: number;
    SQL_ERROR_MESSAGE: string;
    SQL_ERROR_IN_PROCEDURE: string;
    SQL_CMD_TYPE: string;
}

export interface DeliveryPriceInfo {
    Dilivery_Charges: number;
}
export interface Minimum_Delivery_Amount {
    Minimum_Delivery_Amount: number;
}

export interface DeliveryCharge {
    RequestStatus: RequestStatus[];
    Delivery_Price_Info: DeliveryPriceInfo[];
    Minimum_Delivery_Amount: Minimum_Delivery_Amount[];
}
export interface VoucherInfo {
    Voucher_Id: number;
    Voucher_Code: string;
    Voucher_Per: number;
    Discount: number;
}

export interface VoucherResp {
    RequestStatus: RequestStatus[];
    Voucher_Info: VoucherInfo[];
}
export class Order {
    Order_Id: string;
    Cust_id: number;
    Sub_Total: number;
    Voucher_Per: number;
    Voucher_Id: number;
    Discount: number;
    Delivery_Charge: number;
    Net_Amount: number;
    Paid_Amount: number;
    Order_from: string;
    Order_Type: string;
    Pay_Card: number;
    Delivery_time: string;
    Delivery_Note: string;
}

export class OrderDetail {
    Id: number;
    Price_Id: number;
    Item_Name: string;
    Quantity: number;
    Price: number;
    ItemType: string;
    IsPriceEdited: boolean;
    ViewOrder: number;
    PrinterId: number;
    ParentId: number;
    Takeaway_Collection_Bar_Price: number;
    Dilivery_Price: number;
    DineIn_Price: number;
    ThirdParty_Price: number;
    CourseName: string;
    CourseOrder: number;
    PrintStatus: number;
}

export class OrderPayload {
    order: Order;
    OD: OrderDetail[];
   
    constructor() {
        
       this.OD=new Array<OrderDetail>();
       this.order=new Order();
    }
}
export class OrderResp {
    RequestStatus: RequestStatus[];
    OrderDetails: Order[];
}
export class OrderDetailResp {
    RequestStatus: RequestStatus[];
    OrderDetails: OrderDetail[];
    Order: Order[];
}
export interface ShopStatus {
    Mail_Responce: string;
}

export interface MailResponseObject {
    RequestStatus: RequestStatus[];
    ShopStatus: ShopStatus[];
}
 
export interface ShopStatus {
    Shop_Status: boolean;
    PreOrderTimeDelay:number;
    PreOrder:boolean;
}

export interface ShopTiming {
    Id: number;
    Day_Name: string;
    Opening_Time: string;
    Closing_Time: string;
    Active_Status: boolean;
}
export interface AboutContent {
    Id: number;
    About_h1: string;
    About_Content: string;
    About_Image: string;
    Flag: string;
}
export interface AboutContentResp {
    RequestStatus: RequestStatus[];
    AboutContents: AboutContent[];
}
export interface PaymentGatewayContent {
    Id: number;
    Gateway_Type: string;
    Public_Key: string;
    Client_Id: string;
}
export interface PaymentGatewayContentResp {
    RequestStatus: RequestStatus[];
    PaymentGatewayData: PaymentGatewayContent[];
}
export interface AboutFirstSectionResp {
    RequestStatus: RequestStatus[];
    AboutFirstSections: AboutContent[];
}
export interface AboutSecondSectionResp {
    RequestStatus: RequestStatus[];
    AboutSecondSections: AboutContent[];
}
export interface AboutThirdSectionResp {
    RequestStatus: RequestStatus[];
    AboutThirdSections: AboutContent[];
}
export interface ShopTimingResp {
    RequestStatus: RequestStatus[];
    ShopStatus: ShopStatus[];
    ShopTimings: ShopTiming[];
    ShopTimingsforpreorders: ShopTiming[];
}
export interface Archievement {
    Id: number;
    No_Of_Amazing_Dishes: number;
    No_Of_Happy_Customers: number;
    No_Of_Satified_Staff: number;
    No_Of_Working_Years: number;
}
export interface ArchievementResp {
    RequestStatus: RequestStatus[];
    Archievements: Archievement[];
}
export interface HomeBanner {
    Id: number;
    Bnr_Name: string;
    Banner_Description: string;
    Bnr_Img: string;
}
export interface HomeBannerResp {
    RequestStatus: RequestStatus[];
    HomeBanners: HomeBanner[];
}
export interface OfferBanner {
    Id: number;
    Bnr_Name: string;
    Banner_Description: string;
    Bnr_Img: string;
}
export interface OfferBannerResp {
    RequestStatus: RequestStatus[];
    OfferBanners: OfferBanner[];
}
export interface EnableTable {
    Enable_RequestStatus_Booking: boolean;
}
export interface EnableTableResp {
    RequestStatus: RequestStatus[];
    EnableRequestStatusBookingSetup: EnableTable[];
}
export interface Testimonial {
    Id: number;
    About_h1: string;
    About_Content: string;
    About_Image: string;
    Flag: string;
}
export interface TestimonialResp {
    RequestStatus: RequestStatus[];
    Testimonials: Testimonial[];
}
export interface ContactUs {
    Id: number;
    Address: string;
    Email_Address: string;
    Phone: string;
    HeaderPhone:string;
    Openinig_Hours: string;
    Map_Src: string;
    Recaptcha_SiteKey: string;
    Recaptcha_SecretKey: string;
}
export interface ContactUsResp {
    RequestStatus: RequestStatus[];
    ContactDetails: ContactUs[];
}
export interface LogoAndContent {
    Id: number;
    About_h1: string;
    About_Content: string;
    About_Image: string;
    Flag: string;
}
export interface LogoAndContentResp {
    RequestStatus: RequestStatus[];
    LogoAndContents: LogoAndContent[];
}
export interface Size {
    Price_Id: number;
    Name: string;
    Size_Item_Name: string;
    Item_Size_Name: string;
    ItemType: string;
    IsPriceEdited: boolean;
    PrinterId: number;
    CourseName: string;
    CourseOrder: number;
    PrintStatus: number;
    BaseExists: boolean;
    ToppingsExists: boolean;
    Takeaway_Collection_Bar_Price: number;
    Dilivery_Price: number;
    DineIn_Price: number;
    ThirdParty_Price: number;
    MD_Price: number;
    Size_Id: number;
    Item_Id: number;
    Cat_Id: number;
    Item_Desc: string;
    View_Order: number;
    Auto_Show_Extras: boolean;
    Is_Item: number;
    Free_Toppings_Qty: number;
    Auto_Add_Free_Toppings: boolean;
    Free_Toppings_Ids: string;
    Thimb_Image: string;
    Main_Image: string;
    Multiselection_Base: boolean;
    Multiselection_Extras: boolean;
    size?: any;
    LabelCount:number;
}

export interface Item {
    Price_Id: number;
    Name: string;
    CartName: string;
    Size_Item_Name: string;
    Item_Size_Name: string;
    ItemType: string;
    IsPriceEdited: boolean;
    PrinterId: number;
    CourseName: string;
    CourseOrder: number;
    PrintStatus: number;
    SizeExists: boolean;
    BaseExists: boolean;
    ToppingsExists: boolean;
    Takeaway_Collection_Bar_Price: number;
    Dilivery_Price: number;
    DineIn_Price: number;
    ThirdParty_Price: number;
    MD_Price: number;
    Size_Id: number;
    Item_Id: number;
    Cat_Id: number;
    Item_Desc: string;
    View_Order: number;
    Auto_Show_Extras: boolean;
    Is_Item: number;
    Free_Toppings_Qty: number;
    Auto_Add_Free_Toppings: boolean;
    Free_Toppings_Ids: string;
    Thimb_Image: string;
    Main_Image: string;
    Multiselection_Base: boolean;
    Multiselection_Extras: boolean;
    Qty:number;
    ItemTotal:number;
    UniqueId:number;
    selectedSize:Size;
    size: Size[];
    ShowPrice_Price: number;
    Parent_Id:string;
    LabelCount:number;
    LabelAdded:number;
    selectedBase:Item[];
    selectedToppings:Item[];
    ItemUniqueid:string;
}
export interface MealDealResp {
    RequestStatus: RequestStatus[];
    MealDeals: Item[];
}
export interface ImageGallery {
    Id: number;
    Image_Name: string;
    Image_File: string;
}
export interface ImageGalleryResp {
    RequestStatus: RequestStatus[];
    ImageGallerys: ImageGallery[];
}
export interface SocialMedia {
    Id: number;
    Facebook_Link: string;
    Twitter_Link: string;
    Google_Listing_Link: string;
    Youtube_Link: string;
    Instagram_Link:string;
}
export interface SocialMediaResp {
    RequestStatus: RequestStatus[];
    SocialMedias: SocialMedia[];
}

export interface ForgotResponse {
    RequestStatus: RequestStatus[];
}
export class NewItem {
    Price_Id: number;
    Name: string;
    CartName: string;
    Size_Item_Name: string;
    Item_Size_Name: string;
    ItemType: string;
    IsPriceEdited: boolean;
    PrinterId: number;
    CourseName: string;
    CourseOrder: number;
    PrintStatus: number;
    SizeExists: boolean;
    BaseExists: boolean;
    ToppingsExists: boolean;
    Takeaway_Collection_Bar_Price: number;
    Dilivery_Price: number;
    DineIn_Price: number;
    ThirdParty_Price: number;
    MD_Price: number;
    Size_Id: number;
    Item_Id: number;
    Cat_Id: number;
    Item_Desc: string;
    View_Order: number;
    Auto_Show_Extras: boolean;
    Is_Item: number;
    Free_Toppings_Qty: number;
    Auto_Add_Free_Toppings: boolean;
    Free_Toppings_Ids: string;
    Thimb_Image: string;
    Main_Image: string;
    Multiselection_Base: boolean;
    Multiselection_Extras: boolean;
    Qty:number;
    ItemTotal:number;
    UniqueId:number;
    selectedSize:Size;
    size: Size[];
    ShowPrice_Price: number;
    Parent_Id:string;
    LabelCount:number;
    LabelAdded:number;
    selectedBase:Item[];
    selectedToppings:Item[];
    ItemUniqueid:string;
    
    constructor() {
      
        this.Dilivery_Price=0;
        this.DineIn_Price=0;
        this.Takeaway_Collection_Bar_Price=0;
        this.Parent_Id=undefined;
    }
}
export interface DealCourse {
    Deal_Id: number;
    Course_Name: string;
    Selection_Quantity: number;
    Item_Quantity: number;
    Extras_Quantity: number;
    Is_Fix: boolean;
    Price: number;
    Show_Extras: boolean;
    Item: Item[];
    Selected_Quantity:number; 
    DealUniquId:number;
    visible:boolean;
    selectedItems:Item[];
}

export interface DealResponseObject {
    DealCourse: DealCourse[];
}

export interface DealResponse {
    ResponseCode: number;
    ResponseMessage: number;
    ResponseObject: DealResponseObject;
    IsError: boolean;
    ExceptionObject?: any;
}
