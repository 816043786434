import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  constructor(private http: HttpClient) { }
  apiurl=localStorage.getItem("apiurl");
  headersoptions={
    headers:new HttpHeaders({"Content-Type":"application/json"
      
    }).append("api-key","123").append("Authorization","")
  }
  makePayment(stripeToken: any): Observable<any>{
    let body = {
      email: stripeToken.email,
      tokenId: stripeToken.id,
      amount: stripeToken.amount
    };
    let authtoken:string=localStorage.getItem("AuthToken")
    if(authtoken!=null)
    {  
      this.headersoptions.headers= new HttpHeaders({"Content-Type":"application/json","api-key":"123",
      "Authorization":"Bearer "+authtoken
    });  
       
      
    }
    return this.http.post<any>(this.apiurl+'v1/api/getdata',body,this.headersoptions)
    

   
    // const url = "http://localhost:11279/v1/api/getdata"
    // return this.http.post<any>(url,{token:stripeToken})
  }
}
