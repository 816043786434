import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SercallService } from '../sercall.service';
import { ContactUs, ContactUsResp, MailResponseObject } from '../StatusModel';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('recaptcha') recaptchaElement:  ElementRef;
  mapURL: SafeResourceUrl;
  ContactForm:FormGroup;
  submitted:boolean=false;
  errorMsg:string="";
  SuccessMsg:string="";
  disableBtn:boolean=false;
  showLoader:boolean=false;
  constructor(private formbuilder:FormBuilder,private se:SercallService,public sanitizer: DomSanitizer) {
    this.removeSripts();
    this.loadScript('assets/js/scripts.js');
    // let url =localStorage.getItem('AboutContent')[3];
    // let url=JSON.parse(localStorage.getItem("AboutContent"));
    
}

ngOnInit() {
  this.LoadContactUs();
  // this.LoadMap();
  this.ContactForm=this.formbuilder.group({
    Name:['',[Validators.required]],
    Email:['',[Validators.required,Validators.email]], 
    Mobile:['',[Validators.required]],
    Subject:['',[]],
    Msg:['',[Validators.required]]
  });
  this.addRecaptchaScript();
}

renderReCaptch() {
  let SiteKey="";
  var arr = JSON.parse(localStorage.getItem('ContactDetail'));
arr.forEach(function(obj) {
  SiteKey=(obj.Recaptcha_SiteKey);
});
  window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
    'sitekey' : SiteKey,
    'callback': (response) => {
        console.log(response);
    }
  });
}

addRecaptchaScript() {

  window['grecaptchaCallback'] = () => {
    this.renderReCaptch();
  }

  (function(d, s, id, obj){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { obj.renderReCaptch(); return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'recaptcha-jssdk', this));

}

// LoadMap()
// {
//   let url="";
//     var arr = JSON.parse(localStorage.getItem('ContactDetail'));
//     arr.forEach(function(obj) {
//      url=(obj.Map_Src);
//   });
//     this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
// }
contactus:ContactUs[];
LoadContactUs()
{
var payload={"StrFeild1":"","StrFeild2":"","StrFeild3":"","StrFeild4":"","IntFeild1":0,"IntFeild2":0,"DecimalFeild1":0,"DecimalFeild2":0,"CMDTYPE":"Get_Contact_Details"};
this.se.postdata<LoginModel.CommonRreponse<ContactUsResp>>('v1/api/get-set-information-more',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      if(data.ResponseObject.RequestStatus[0].ERROR_NO==0)
      {
        this.contactus=data.ResponseObject.ContactDetails;
        this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.contactus[0].Map_Src);
        localStorage.setItem("ContactDetail",JSON.stringify(this.contactus));
        
      }
    }
}).catch(e => {

  if (e.status == 401) {
    if (this.se.CheckRefreshToken()) {
      this.LoadContactUs();

    }
  }
});
}

get f() { return this.ContactForm.controls; }
onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.ContactForm.invalid) {
      return;
  }
  this.disableBtn=true;
  this.showLoader=true;
  this.errorMsg="";
  
  var payload= {
    "Name":this.ContactForm.controls.Name.value,
    "Mobile":this.ContactForm.controls.Mobile.value,
    "email":this.ContactForm.controls.Email.value,
    "Subject":this.ContactForm.controls.Subject.value,
    "Msg":this.ContactForm.controls.Msg.value
}
  this.se.postdata<LoginModel.CommonRreponse<MailResponseObject>>('v1/api/send-mail',payload).then(data=>{
    if(data.ResponseCode==0)
    {
      this.SuccessMsg=data.ResponseObject.ShopStatus[0].Mail_Responce;
      this.submitted=false;
      this.ContactForm.markAsPristine();
      this.ContactForm.reset();
    }
    else
    {
      this.errorMsg=data.ResponseMessage;
      this.clearErrorMsg();
      //show error msg from server
    }
    this.disableBtn=false;
    this.showLoader=false;
  });
  
}

clearErrorMsg()
{
setTimeout(() => {
  this.errorMsg="";
}, 2500);
}
public removeSripts()
{
  var arr=document.getElementsByTagName('script');
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].src.includes('scripts.js'))
    {
      arr[index].remove();
      break;
    }
  }
}
public loadScript(url: string) {
const body = <HTMLDivElement> document.body;
 
const script = document.createElement('script');
script.innerHTML = '';
script.src = url;
script.async = false;
script.defer = true;
body.appendChild(script);
} 
}
