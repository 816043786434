import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor() {

    this.loadCart();
  }

  subtotal=0;
  l = new Array()
  cartempty = true

  decCart(obj) {
    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].itemid == obj.itemid) {
        cart[i].qty = cart[i].qty - 1
        if(cart[i].qty<=0)
        {
          cart.splice(i,1);
        }
        added = true;
      }
      
    }
    
    localStorage.setItem('_cart',JSON.stringify(cart))
    this.loadCart()
    return false
  }
  incCart(obj) {
    
    var cart = new Array();
    cart = this.getCart();
    var added = false
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].itemid == obj.itemid) {
        cart[i].qty = cart[i].qty + 1
      
        added = true;
      }

    }
     
    localStorage.setItem('_cart',JSON.stringify(cart))
    this.loadCart()
    return false
  }
  ngOnInit() {

  }
  loadCart() {
    this.subtotal=0
    this.l = this.getCart();
    if (this.l.length > 0)
    {
      this.cartempty = false
      for (var i = 0; i < this.l.length; i++) {
         
        this.subtotal+=(this.l[i].price*this.l[i].qty);
      }
    }
    else
    {
      this.cartempty=true
    }



  }
  getCart() {
    var cart = localStorage.getItem('_cart')
    if (cart != null && cart != "")
      return JSON.parse(cart)
    else
      return [];


  }
}
